import { AuthMethod } from "../enums/auth-method.enum";

/**
 * @file auth.model.ts
 * @description model file for compare data from table `USERS`.
 */
export class Auth {
    username!: string;
    password?: string;
    method!: AuthMethod;
    screenId?: number;
    code?: number;
    ip!: string;

    constructor(partial: Partial<Auth>) {
        Object.assign(this, partial);
    }
}