<div class="ml-4 mr-4">
    <nz-page-header class="site-page-header" nzTitle="Máquinas" nzSubtitle="Máquinas en el sistema">
        <nz-page-header-extra>
            <nz-space>
                <button
                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button" (click)="createMachine()">
                    Añadir máquina
                </button>
            </nz-space>
        </nz-page-header-extra>
    </nz-page-header>

    <nz-table #machinesTable [nzData]="machines" [nzLoading]="loadingTable">
        <thead>
            <tr>
                <th>Nombre</th>
                <th>Dirección IP</th>
                <th>Nombre de la cola</th>
                <th>Estado</th>
                <th>Acciones</th>
            </tr>
        </thead>
        <tbody>
            @for (data of machinesTable.data; track idx; let idx = $index) {
            <tr>
                <td>{{ data.name }}</td>
                <td>{{ data.ip }}</td>
                <td>{{ data.queueName }}</td>
                <td>{{ data.state }}</td>
                <td>
                    <div class="buttonsDnDTableContainer">
                        <img src="assets/icons/eye-icon.svg" class="h-6 w-6" (click)="seeMachine(data.id)" title="Ver">
                        <nz-divider nzType="vertical"></nz-divider>
                        <img src="assets/icons/edit-icon.svg" class="h-6 w-6" (click)="editMachine(data.id)"
                            title="Editar">
                        <nz-divider nzType="vertical"></nz-divider>
                        <img src="assets/icons/xmark-icon.svg" class="h-6 w-6" (click)="deleteMachine(data.id)"
                            title="Eliminar">
                    </div>
                </td>
            </tr>
            }
        </tbody>
    </nz-table>
</div>

<nz-drawer [nzClosable]="false" [nzVisible]="drawerVisible" nzPlacement="right" [nzTitle]="drawerTitle"
    (nzOnClose)="closeDrawer()" [nzWidth]="'500px'" [nzMaskClosable]="fieldsReadOnly">
    <ng-container *nzDrawerContent>
        <form (ngSubmit)="saveEditing()">

            <label>Nombre:</label>
            <input nz-input [value]="machineSelected.name ? machineSelected.name : ''" type="text"
                class="searchSelectors" [readOnly]="fieldsReadOnly" id="name" />

            <label>Dirección IP:</label>
            <input nz-input [value]="machineSelected.ip ? machineSelected.ip : ''" type="text"
                class="searchSelectors" [readOnly]="fieldsReadOnly" id="ip" />

            <label>Nombre de la cola:</label>
            <input nz-input [value]="machineSelected.queueName ? machineSelected.queueName : ''" type="text"
                class="searchSelectors" [readOnly]="fieldsReadOnly" id="queueName" />

            @if (!fieldsReadOnly) {
            <button
                class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit">
                Guardar
            </button>
            <nz-divider nzType="vertical"></nz-divider>
            <button
                class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="button" (click)="cancelEditing()">
                Cancelar
            </button>
            }
        </form>
    </ng-container>
</nz-drawer>
