import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { ConfigurationDto } from 'src/app/shared/dto/configuration.dto';
import { ConfigurationService } from '../../services/configuration.service';
import _ from 'lodash';
import { ConfigurationTypesEnum } from 'src/app/shared/enums/configuration-types.enum';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-configuration',
    standalone: true,
    imports: [
        FormsModule,
        NzSwitchModule,
        NzDividerModule,
        NzPageHeaderModule,
        NzSpaceModule,
        NzMessageModule,
    ],
    templateUrl: './configuration.component.html',
    styleUrl: './configuration.component.scss'
})
export class ConfigurationComponent implements OnInit, OnDestroy {

    private readonly configurationService = inject(ConfigurationService);
    private readonly toast = inject(NzMessageService);

    configurations: ConfigurationDto[] = [];
    configurationsNoModified: ConfigurationDto[] = [];
    configurationsTypesEnum = ConfigurationTypesEnum;

    private unsubscribe$ = new Subject<void>();

    async ngOnInit() {
        try {
            await this.configurationService.setupWebSocket();
            this.configurationService.configurations$
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe({
                    next: (configurations) => {
                        this.configurations = _.cloneDeep(configurations);
                        this.configurationsNoModified = _.cloneDeep(configurations);
                        this.parseConfiguration();
                    },
                    error: () => {
                        this.toast.error('Error cargando artículos');
                    }
                });
        } catch (error: any) {
            this.toast.create('error', `${error.message}`);
        }
    }

    ngOnDestroy(): void {
        this.configurationService.disconnectWebSocket();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    async saveConfiguration() {
        try {
            if (this.configurations.length === 0) {
                this.toast.error('No hay configuraciones para guardar');
                return;
            }

            const configurationsToSave: ConfigurationDto[] = [];

            for (let i = 0; i < this.configurations.length; i++) {
                if (this.configurations[i].value !== this.configurationsNoModified[i].value) {
                    configurationsToSave.push(this.configurations[i]);
                }
            }

            if (configurationsToSave.length === 0) {
                this.toast.error('No hay cambios que guardar');
                return;
            }

            configurationsToSave.map((config) => {
                config.value = config.value.toString();
            });

            const updatedConfigurations: ConfigurationDto[] = await this.configurationService.updateConfigurations(configurationsToSave);

            for (const config of updatedConfigurations) {
                const configIndex: number = this.configurations.findIndex((c) => c.id === config.id);

                if (configIndex !== -1) {
                    this.configurations[configIndex] = config;
                }
            }

            this.parseConfiguration();
            this.configurationsNoModified = _.cloneDeep(this.configurations);
            this.toast.success('Configuración guardada');
        } catch (error: any) {
            this.toast.error(`Error al guardar la configuración. Error: ${error.message}`);
            console.error(error);
        }
    }

    private parseConfiguration(): void {
        this.configurations.map((config) => {
            switch (config.type) {
                case ConfigurationTypesEnum.BOOLEAN:
                    config.value = config.value.toString().toLowerCase() === 'true';
                    break;
                case ConfigurationTypesEnum.NUMBER:
                    config.value = Number(config.value);
                    break;
            }
        });
    }
}
