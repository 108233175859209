import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Subscription } from 'rxjs';
import { ORDERS } from 'src/app/core/constants/storage';
import { UserLoggedService } from 'src/app/core/services/userLogged.service';
import { ArticleBatchDto } from 'src/app/shared/dto/article-batch.dto';
import { ArticleDto } from 'src/app/shared/dto/article.dto';
import { PriorityDto } from 'src/app/shared/dto/priority.dto';
import { ProductionOrderProcessDto } from 'src/app/shared/dto/production-order-process.dto';
import { ProductionOrderDto } from 'src/app/shared/dto/production-order.dto';
import { ProductionOrderQueryParams } from 'src/app/shared/models/production-order/production-order-query-params.model';
import { BaseService } from 'src/app/shared/services/base.service';
import { SocketService } from 'src/app/shared/services/socket.service';
import { Messages } from 'src/app/shared/sockets/enums/messages';
import { Client, formatClientRoom, RoomNames } from 'src/app/shared/sockets/enums/rooms';

@Injectable({ providedIn: 'root', })
export class ProductionOrderService extends BaseService {
    private readonly ordersSubject = new BehaviorSubject<ProductionOrderDto[]>([]);
    public orders$ = this.ordersSubject.asObservable();
    private messageSubscription: Subscription | undefined;

    private readonly socketService = inject(SocketService);
    private readonly userLoggedService = inject(UserLoggedService);

    constructor(
        httpClient: HttpClient,
    ) {
        super(httpClient, 'production-orders');
    }

    async setupWebSocket(): Promise<void> {
        try {
            // ToDo: Cuando se habilite por configuración el filtrado de órdenes por operador, entonces habrá que habilitar esto
            // this.ordersSubject.next(await this.findAllByUserId(this.userLoggedService.userLogged.id));
            this.ordersSubject.next(await this.findAll(true));

            this.socketService.conectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.PRODUCTION_ORDERS_ROOM));
            this.messageSubscription = this.socketService.getNotifications(Messages.PRODUCTION_ORDERS_CHANGED)
                .subscribe(async () => {
                    //TODO: add pagination

                    // ToDo: Cuando se habilite por configuración el filtrado de órdenes por operador, entonces habrá que habilitar esto
                    // const orders = await this.findAllByUserId(this.userLoggedService.userLogged.id, true);
                    const orders = await this.findAll(true);
                    this.ordersSubject.next(orders);
                });
        } catch (e: any) {
            throw new Error(`Error al establecer conexión con el socket de Órdenes de Producción. Error: ${e.message}`);
        }
    }

    disconnectWebSocket(): void {
        this.messageSubscription!.unsubscribe();
        this.socketService.disconnectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.PRODUCTION_ORDERS_ROOM));
    }

    async getOrderById(id: number): Promise<ProductionOrderDto> {
        try {
            if (id <= 0) {
                throw new Error('El id de la orden ha de ser mayor de 0');
            }

            const request = this.httpClient.get<ProductionOrderDto>(`${this.url}/${id}`);

            return await lastValueFrom(request);
        } catch (e: any) {
            throw new Error(`No se ha podido obtener la orden ${id ?? ''}. Error: ${e.message}`);
        }
    }

    async findAll(fromSocket: boolean = false): Promise<ProductionOrderDto[]> {
        try {
            let orders: ProductionOrderDto[] = this.getOrdersFromLocalStorage();

            if (orders && orders.length > 0 && !fromSocket) {
                return orders;
            }

            const request = this.httpClient.get<ProductionOrderDto[]>(`${this.url}/findAll`);

            orders = await lastValueFrom(request);

            localStorage.setItem(ORDERS, JSON.stringify(orders));

            return orders;
        } catch (e: any) {
            throw new Error(`No se ha encontrado ninguna orden de producción. Error: ${e.message}`);
        }
    }

    // ToDo: Cuando se habilite por configuración el filtrado de órdenes por operador, entonces habrá que habilitar esto
    // async findAllByUserId(userId: number, fromSocket: boolean = false): Promise<ProductionOrderDto[]> {
    //     try {
    //         let orders: ProductionOrderDto[] = this.getOrdersFromLocalStorage();

    //         if (orders && orders.length > 0 && !fromSocket) {
    //             return orders;
    //         }

    //         const request = this.httpClient.get<ProductionOrderDto[]>(`${this.url}/findAllByUserId/${userId}`);

    //         orders = await lastValueFrom(request);

    //         localStorage.setItem(ORDERS, JSON.stringify(orders));

    //         return orders;
    //     } catch (e: any) {
    //         throw new Error(`No se ha encontrado ninguna orden de producción. Error: ${e.message}`);
    //     }
    // }

    async getByState(bodyParams: ProductionOrderQueryParams): Promise<ProductionOrderDto[]> {
        try {
            const request = this.httpClient.post<ProductionOrderDto[]>(`${this.url}`, bodyParams);

            const productionOrders: ProductionOrderDto[] = await lastValueFrom(request);

            if (!this.validateProductionOrders(productionOrders)) {
                throw new Error('Todas las órdenes han de contener al menos un proceso');
            }

            localStorage.setItem(ORDERS, JSON.stringify(productionOrders));

            return productionOrders;
        } catch (e: any) {
            throw new Error(`No se ha podido obtener las órdenes de producción por estado. Error: ${e.message}`);
        }
    }

    async getByScreen(screenId: number): Promise<ProductionOrderDto[]> {
        try {
            if (screenId <= 0) {
                throw new Error('El id de la pantalla ha de ser mayor de 0');
            }

            const body = {
                screenId: screenId,
            };

            const data = await this.httpClient.post<ProductionOrderDto[]>(this.url, body);

            const productionOrders: ProductionOrderDto[] = await lastValueFrom(data);

            if (!this.validateProductionOrders(productionOrders)) {
                throw new Error('Todas las órdenes han de contener al menos un proceso');
            }

            localStorage.setItem(ORDERS, JSON.stringify(productionOrders));

            return productionOrders;
        } catch (e: any) {
            throw new Error(`No se han podido obtener las órdenes de producción por pantalla. Error: ${e.message}`);
        }
    }

    async createOrder(order: ProductionOrderDto): Promise<ProductionOrderDto> {
        try {
            if (order === null || order === undefined) {
                throw new Error('La orden no es válida');
            }

            const createdOrder: ProductionOrderDto = await lastValueFrom(this.httpClient.post<ProductionOrderDto>(`${this.url}/create`, order));

            if (!createdOrder) {
                throw new Error('Error creando la orden de producción');
            }

            this.updateOrderLocalStorage(createdOrder, true);

            return createdOrder;
        } catch (e: any) {
            throw new Error(`Error creando la orden de producción. Error: ${e.message}`);
        }
    }

    async updateOrder(order: ProductionOrderDto): Promise<ProductionOrderDto> {
        try {
            if (order === null || order === undefined) {
                throw new Error('La orden no es válida');
            }

            const updatedOrder: ProductionOrderDto = await lastValueFrom(this.httpClient.patch<ProductionOrderDto>(`${this.url}/update`, order));

            if (!updatedOrder) {
                throw new Error('Error actualizando la orden de producción');
            }

            this.updateOrderLocalStorage(updatedOrder, false);

            return updatedOrder;
        } catch (e: any) {
            throw new Error(`Error actualizando la orden de producción. Error: ${e.message}`);
        }
    }

    async updateOrderStatus(orderId: number, statusId: number): Promise<ProductionOrderDto> {
        try {
            if (orderId <= 0 || statusId <= 0) {
                throw new Error('El id de la orden y del estado han de ser mayor de 0');
            }

            const updatedOrder: ProductionOrderDto = await lastValueFrom(this.httpClient.patch<ProductionOrderDto>(`${this.url}/updateOrderStatus/${orderId}/${statusId}`, {}));

            if (!updatedOrder) {
                throw new Error('Error actualizando el estado de la orden de producción');
            }

            this.updateOrderLocalStorage(updatedOrder, false);

            return updatedOrder;
        } catch (e: any) {
            throw new Error(`Error actualizando el estado de la orden de producción. Error: ${e.message}`);
        }
    }

    async deleteOrder(orderId: number): Promise<ProductionOrderDto> {
        try {
            if (orderId <= 0) {
                throw new Error('El id de la orden ha de ser mayor de 0');
            }

            const deletedOrder: ProductionOrderDto = await lastValueFrom(this.httpClient.delete<ProductionOrderDto>(`${this.url}/delete/${orderId}`));

            if (!deletedOrder) {
                throw Error(`No se ha podido eliminar la orden ${orderId}`);
            }

            this.removeFromLocalStorage(orderId);

            return deletedOrder;
        } catch (e: any) {
            throw new Error(`Error eliminando la orden de producción ${orderId ?? ''}. Error: ${e.message}`);
        }
    }

    async setAsUrgent(orderId: number): Promise<ProductionOrderDto> {
        try {
            if (!orderId || orderId <= 0) {
                throw new Error('El id de la orden no es válido');
            }

            const updatedOrder: ProductionOrderDto = await lastValueFrom(this.httpClient.patch<any>(`${this.url}/setPriorityAsUrgent/${orderId}`, {}));

            this.updateOrderLocalStorage(updatedOrder, false);

            return updatedOrder;
        } catch (e: any) {
            throw new Error(`No se ha podido marcar la orden como urgente. Error: ${e.message}`);
        }
    }

    async getPriorities(): Promise<PriorityDto[]> {
        try {
            return await lastValueFrom(this.httpClient.get<PriorityDto[]>(`${this.url}/getPriorities`));
        } catch (e: any) {
            throw new Error(`No se han podido obtener las prioridades. Error: ${e.message}`);
        }
    }

    async updateOrderPriority(orderId: number, priority: PriorityDto): Promise<ProductionOrderDto> {
        try {
            if (!orderId || orderId <= 0) {
                throw new Error('El id de la orden no es válido');
            }

            if (priority === null || priority === undefined) {
                throw new Error('Prioridad no válida');
            }

            const request = this.httpClient.patch<ProductionOrderDto>(`${this.url}/updateOrderPriority/${orderId}`, priority);

            return await lastValueFrom(request);
        } catch (e: any) {
            throw new Error(`No se han podido actualizar la prioridad para la orden ${orderId ?? ''}. Error: ${e.message}`);
        }
    }

    getOrderByIdFromLocalStorage(orderId: number): ProductionOrderDto {
        const ordersStr: string | null = localStorage.getItem(ORDERS);

        if (!ordersStr) {
            throw new Error(`No se han encontrado órdenes`);
        }

        const orders: ProductionOrderDto[] = JSON.parse(ordersStr) as ProductionOrderDto[];

        const order: ProductionOrderDto | undefined = orders.find((order) => order.id === orderId);

        if (!order) {
            throw new Error(`No se ha podido encontrar la orden ${orderId}`);
        }

        return order;
    }

    updateOrderLocalStorage(productionOrder: ProductionOrderDto, isNew: boolean) {
        const orders = this.getOrdersFromLocalStorage();

        if (orders) {
            if (isNew) {
                orders.push(productionOrder);
            } else {
                const orderIndex = orders.findIndex((order) => order.id == productionOrder.id);

                if (orderIndex != -1) {
                    orders[orderIndex] = productionOrder;
                }
            }

            localStorage.setItem(ORDERS, JSON.stringify(orders));
        }
    }

    getOrdersFromLocalStorage(): ProductionOrderDto[] {
        const ordersStr: string | null = localStorage.getItem(ORDERS);

        if (!ordersStr) {
            return [];
        }

        return JSON.parse(ordersStr) as ProductionOrderDto[];
    }

    /**
     * Updates the articles or the article batches in the orders in the local storage.
     * @param articles Array of articles to update in the orders in the local storage
     * @param articleBatches Array of article batches to update in the orders in the local storage
     */
    updateArticleAndBatchInOrdersLocalStorage(articles: ArticleDto[], articleBatches: ArticleBatchDto[]): void {
        try {
            const ordersString = localStorage.getItem(ORDERS);

            if (!ordersString) {
                throw new Error('No se han encontrado ordenes en el local storage');
            }

            const orders: ProductionOrderDto[] = JSON.parse(ordersString);

            for (const order of orders) {
                if (!order.productionOrderProcesses) {
                    throw new Error('No se han encontrado procesos en la orden');
                }

                for (let i = 0; i < order.productionOrderProcesses.length; i++) { // ToDo: This logic should be removed when the sockets takes in consideration the articles and batches
                    const process = order.productionOrderProcesses[i];

                    if (process.article && process.article.useBatches && process.articleBatches) {
                        for (let j = 0; j < process.articleBatches.length; j++) {
                            const foundBatch = articleBatches.find(b => process.articleBatches![j].id === b.id);

                            if (foundBatch) {
                                process.articleBatches![j] = foundBatch;
                            }
                        }
                    } else if (process.article && !process.article.useBatches) {
                        const articleToUpdate = articles.find((a) => a.id === process.article?.id);

                        if (articleToUpdate) {
                            process.article = articleToUpdate;
                        }
                    }
                }
            }

            localStorage.setItem(ORDERS, JSON.stringify(orders));
        } catch (e: any) {
            throw new Error(`No se ha podido actualizar el artículo en el local storage. Error: ${e.message}`);
        }
    }

    private validateProductionOrders(productionOrders: ProductionOrderDto[],): boolean {
        return productionOrders.every((order, index, productionOrders) => {
            return (order.productionOrderProcesses as ProductionOrderProcessDto[]).length > 0;
        });
    }

    private removeFromLocalStorage(orderId: number) {
        let orders: ProductionOrderDto[] = this.getOrdersFromLocalStorage();

        if (orders.length > 0) {
            orders = orders.filter(o => {
                return o.id != orderId;
            });

            localStorage.setItem(ORDERS, JSON.stringify(orders));
        }
    }
}
