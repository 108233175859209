import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Subscription } from 'rxjs';
import { CONFIGURATIONS } from 'src/app/core/constants/storage';
import { ConfigurationDto } from 'src/app/shared/dto/configuration.dto';
import { BaseService } from 'src/app/shared/services/base.service';
import { SocketService } from 'src/app/shared/services/socket.service';
import { Messages } from 'src/app/shared/sockets/enums/messages';
import { Client, formatClientRoom, RoomNames } from 'src/app/shared/sockets/enums/rooms';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService extends BaseService {
    private readonly configurationsSubject = new BehaviorSubject<ConfigurationDto[]>([]);
    public configurations$ = this.configurationsSubject.asObservable();

    private readonly socketService = inject(SocketService);
    private messageSubscription: Subscription | undefined;

    constructor(httpClient: HttpClient) {
        super(httpClient, 'configurations');
    }

    async setupWebSocket(): Promise<void> {
        try {
            this.configurationsSubject.next(await this.getConfigurations());
            this.socketService.conectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.CONFIGURATIONS_ROOM));
            this.messageSubscription = this.socketService.getNotifications(Messages.CONFIGURATIONS_CHANGED).subscribe(async () => {
                const response = await this.getConfigurations();
                this.configurationsSubject.next(response);
            });
        } catch (e: any) {
            throw new Error(`Error al establecer conexión con el socket de Configuraciones. Error: ${e.message}`);
        }
    }

    disconnectWebSocket(): void {
        this.messageSubscription!.unsubscribe();
        this.socketService.disconnectSocketRoom(formatClientRoom(Client.DEFAULT_CLIENT, RoomNames.CONFIGURATIONS_ROOM));
    }

    async getConfigurationByKey(key: string): Promise<ConfigurationDto> {
        try {
            const configuration: ConfigurationDto | undefined = (await this.getConfigurations()).find(config => config.key === key);

            if (!configuration) {
                throw new Error(`No se ha encontrado la configuración por la key ${key}`);
            }

            return configuration;
        } catch (e: any) {
            throw new Error(`No se ha podido encontrar la configuración. Error: ${e.message}`);
        }
    }

    async getConfigurations(): Promise<ConfigurationDto[]> {
        try {
            let configurations: ConfigurationDto[] = this.getFromLocalStorage();

            if (configurations.length > 0) {
                return configurations;
            }

            configurations = await lastValueFrom(this.httpClient.get<ConfigurationDto[]>(`${this.url}/findAll`));

            this.saveConfigurationInLocalStorage(configurations);

            return configurations;
        } catch (e: any) {
            throw new Error(`No se ha podido encontrar la configuración. Error: ${e.message}`);
        }
    }

    async updateConfigurations(configurations: ConfigurationDto[]): Promise<ConfigurationDto[]> {
        try {
            if (configurations.length === 0) {
                throw new Error('La lista de configuraciones está vacía');
            }

            const updatedConfigurations: ConfigurationDto[] = await lastValueFrom(this.httpClient.patch<ConfigurationDto[]>(`${this.url}`, configurations));

            this.updateConfigurationLocalStorage(updatedConfigurations, false);

            return updatedConfigurations;
        } catch (e: any) {
            throw new Error(`No se ha podido actualizar la configuración. Error: ${e.message}`);
        }
    }

    private saveConfigurationInLocalStorage(configurations: ConfigurationDto[]): void {
        localStorage.setItem(CONFIGURATIONS, JSON.stringify(configurations));
    }

    private getFromLocalStorage(): ConfigurationDto[] {
        const configurationsStr: string | null = localStorage.getItem(CONFIGURATIONS);

        if (!configurationsStr) {
            return [];
        }

        return JSON.parse(configurationsStr) as ConfigurationDto[];
    }

    private updateConfigurationLocalStorage(configurationsToUpdate: ConfigurationDto[], isNew: boolean) {
        const configurations = this.getFromLocalStorage();

        if (configurations) {
            for (const cofiguration of configurationsToUpdate) {

                if (isNew) {
                    configurations.push(cofiguration);
                } else {
                    const configIndex = configurations.findIndex((config) => config.id == cofiguration.id);

                    if (configIndex != -1) {
                        configurations[configIndex] = cofiguration;
                    }
                }
            }

            localStorage.setItem(CONFIGURATIONS, JSON.stringify(configurations));
        }
    }
}
