import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { DatePipe, NgClass, registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { AfterViewInit, Component, ElementRef, inject, LOCALE_ID, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import _ from 'lodash';
import { ɵNzTransitionPatchDirective } from 'ng-zorro-antd/core/transition-patch';
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';
import { NzDividerComponent } from 'ng-zorro-antd/divider';
import { NzDrawerComponent, NzDrawerContentDirective } from 'ng-zorro-antd/drawer';
import { NzIconDirective } from 'ng-zorro-antd/icon';
import { NzInputDirective, NzInputGroupComponent, NzInputGroupWhitSuffixOrPrefixDirective } from 'ng-zorro-antd/input';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzPageHeaderComponent, NzPageHeaderContentDirective, NzPageHeaderExtraDirective } from 'ng-zorro-antd/page-header';
import { NzProgressComponent } from 'ng-zorro-antd/progress';
import { NzOptionComponent, NzSelectComponent } from 'ng-zorro-antd/select';
import { NzTableCellDirective, NzTableComponent, NzTableFixedRowComponent, NzTableQueryParams, NzTableSortOrder, NzTbodyComponent, NzTdAddOnComponent, NzThAddOnComponent, NzTheadComponent, NzThMeasureDirective, NzTrDirective, NzTrExpandDirective } from 'ng-zorro-antd/table';
import { Subject, takeUntil } from 'rxjs';
import { DrawerMode } from 'src/app/core/enums/drawer-mode';
import { PermissionService } from 'src/app/core/services/permission.service';
import { ScreenLoggedService } from 'src/app/core/services/screenLogged.service';
import { UserLoggedService } from 'src/app/core/services/userLogged.service';
import { ArticleBatchDto } from 'src/app/shared/dto/article-batch.dto';
import { ArticleDto } from 'src/app/shared/dto/article.dto';
import { AssessDetailDto } from 'src/app/shared/dto/assess-detail.dto';
import { AssessDto } from 'src/app/shared/dto/assess.dto';
import { CustomerDto } from 'src/app/shared/dto/customer.dto';
import { MachineDto } from 'src/app/shared/dto/machine.dto';
import { PriorityDto } from 'src/app/shared/dto/priority.dto';
import { ProductionOrderProcessDto } from 'src/app/shared/dto/production-order-process.dto';
import { ProductionPauseDto } from 'src/app/shared/dto/production-pause.dto';
import { ProductionProcessDto } from 'src/app/shared/dto/production-process.dto';
import { ProductionRouteDto } from 'src/app/shared/dto/production-route.dto';
import { RawMaterialDto } from 'src/app/shared/dto/rawMaterial.dto';
import { ScreenDto } from 'src/app/shared/dto/screen.dto';
import { UserDto } from 'src/app/shared/dto/user.dto';
import { ProductionOrderStateIdsEnum } from 'src/app/shared/enums/production-order-state-ids.enum';
import { ProductionOrderStatesEnum } from 'src/app/shared/enums/production-order-states.enum';
import { datesAreOnSameDay } from 'src/app/shared/utils/utils';
import { ModalAddRawMaterialsComponent } from '../../components/modals/modal-add-raw-materials/modal-add-raw-materials.component';
import { ModalRawMaterialComponent } from '../../components/modals/modal-raw-material/modal-raw-material.component';
import { ModalSelectArticleBatchComponent } from '../../components/modals/modal-select-article-batch/modal-select-article-batch.component';
import { ArticleBatchesService } from '../../services/article-batches.service';
import { ArticlesService } from '../../services/articles.service';
import { AssessService } from '../../services/assess.service';
import { CustomersService } from '../../services/customers.service';
import { MachineService } from '../../services/machine.service';
import { ProductionOrderProcessesService } from '../../services/production-order-processes.service';
import { ProductionOrderService } from '../../services/production-order.service';
import { ProductionProcessesService } from '../../services/production-processes.service';
import { ProductionRoutesService } from '../../services/production-routes.service';
import { UserService } from '../../services/user.service';
import { ProductionOrderDto } from './../../../shared/dto/production-order.dto';

registerLocaleData(localeEs);

@Component({
    selector: 'app-production-orders',
    templateUrl: './production-orders.component.html',
    styleUrl: './production-orders.component.scss',
    standalone: true,
    providers: [
        { provide: LOCALE_ID, useValue: 'es-ES' }
    ],
    imports: [
        NzPageHeaderComponent,
        NzPageHeaderExtraDirective,
        NzInputGroupComponent,
        ɵNzTransitionPatchDirective,
        NzInputGroupWhitSuffixOrPrefixDirective,
        NzInputDirective,
        ReactiveFormsModule,
        FormsModule,
        NzIconDirective,
        NzPageHeaderContentDirective,
        NzTableComponent,
        NzTheadComponent,
        NzTrDirective,
        NzTableCellDirective,
        NzThMeasureDirective,
        NzThAddOnComponent,
        NzTbodyComponent,
        NzTdAddOnComponent,
        NgClass,
        NzProgressComponent,
        NzDividerComponent,
        NzTrExpandDirective,
        NzTableFixedRowComponent,
        NzDrawerComponent,
        NzDrawerContentDirective,
        NzSelectComponent,
        NzOptionComponent,
        CdkDropList,
        CdkDrag,
        NzDatePickerComponent,
        DatePipe,
        NzMessageModule
    ]
})

export class ProductionOrdersComponent implements OnInit, OnDestroy, AfterViewInit {
    [x: string]: any;
    @ViewChild('nestedTable', { static: false }) table?: NzTableComponent<ProductionOrderDto>;
    @ViewChild('innerTable', { static: false }) innerTable?: NzTableComponent<any>;
    @ViewChild('tableContainer') private readonly _tableContainer!: ElementRef;

    public ProductionOrderStates = ProductionOrderStatesEnum;

    showFinished: boolean = false;
    productionOrders: ProductionOrderDto[] = [];
    productionOrdersFiltered: ProductionOrderDto[] = [];
    loading: boolean = false;
    drawerMode: DrawerMode = DrawerMode.NONE;
    routeActually: string = '';
    title: string = '';
    numElemPerPage: number = 9;
    countOrders!: number;
    numPage: any = 1;
    tamPage: any = 9;
    sort: {
        key: string;
        value: NzTableSortOrder;
    }[] = [];

    tableHeight!: number;
    customerName: string = '';
    visibleForm = false;
    screenLogged: ScreenDto | null = null;
    isAdmin: boolean = false;
    filterText: string = '';
    orderSelected: ProductionOrderDto | null = new ProductionOrderDto();
    drawerVisible: boolean = false;
    drawerTitle: string = '';
    fieldsReadOnly: boolean = true;
    startDate: Date = new Date();
    deadLineDate: Date = new Date();
    confirmatedDate: Date = new Date();
    deliveryDate: Date = new Date();
    selectedEditOrder: ProductionOrderDto = new ProductionOrderDto();
    isNewOrder: boolean = false;
    customerSelected: CustomerDto = new CustomerDto();
    customers: CustomerDto[] = [];
    routes: ProductionRouteDto[] = [];
    routeSelected: ProductionRouteDto | undefined;
    articles: ArticleDto[] = [];
    originalArticleStocks: ArticleDto[] = [];
    articleSelected: ArticleDto = new ArticleDto();
    productionProcessess: ProductionProcessDto[] = [];
    selectedProductionProcesses: ProductionProcessDto[] = [];
    previousProductionProcessTableRows: ProductionProcessDto[] = [];
    productionProcessTableRows: ProductionOrderProcessDto[] = [];
    machines: MachineDto[] = [];
    machineSelected: MachineDto = new MachineDto();
    assessments: AssessDto[] = [];
    assessSelected: AssessDto = new AssessDto();
    priorities: PriorityDto[] = [];
    prioritySelected: PriorityDto = new PriorityDto();
    prioritySortState: 'asc' | 'desc' | 'id' = 'id';
    orderStarted: boolean = false;
    userName: string | undefined = '';
    users: UserDto[] = [];
    selectedUsers: UserDto[] = [];
    userLogged: UserDto;

    private articlesBatches: ArticleBatchDto[] = [];
    private originalArticleBatchesStocks: ArticleBatchDto[] = [];
    private unsubscribe$ = new Subject<void>();

    public readonly productionOrderService = inject(ProductionOrderService);
    private readonly toast = inject(NzMessageService);
    private readonly router = inject(Router);
    private readonly userLoggedService = inject(UserLoggedService);
    private readonly screenLoggedService = inject(ScreenLoggedService);
    private readonly activatedRoute = inject(ActivatedRoute);
    private readonly ordersService = inject(ProductionOrderService);
    private readonly orderProcessService = inject(ProductionOrderProcessesService);
    private readonly customerService = inject(CustomersService);
    private readonly productionRouteService = inject(ProductionRoutesService);
    private readonly articleService = inject(ArticlesService);
    private readonly articleBatchesService = inject(ArticleBatchesService);
    private readonly productionProcessesService = inject(ProductionProcessesService);
    private readonly machineService = inject(MachineService);
    private readonly assessService = inject(AssessService);
    private readonly modalService = inject(NzModalService);
    private readonly permissionService = inject(PermissionService);
    private readonly usersService = inject(UserService);

    constructor() {
        this.activatedRoute.queryParams.subscribe((params) => {
            const customerNameParam = params['customerName'];

            if (customerNameParam !== null && customerNameParam !== undefined) {
                this.customerName = params['customerName'];
                this.title = ' - ' + params['customerName'];
            }
        });

        this.screenLogged = _.cloneDeep(this.screenLoggedService.screenLogged);
        this.userLogged = _.cloneDeep(this.userLoggedService.userLogged);

        this.isAdmin = this.userLoggedService.hasRole([
            'administrador',
            'operador'
        ]);
    }

    async ngOnInit(): Promise<void> {
        this.userName = this.userLogged.username;

        await this.productionOrderService.setupWebSocket();
        this.productionOrderService.orders$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (orders) => {
                    this.productionOrders = orders;
                    this.productionOrdersFiltered = _.cloneDeep(this.productionOrders);
                    this.selectedEditOrder = this.productionOrders.find(order => order.id === this.selectedEditOrder.id) ?? this.selectedEditOrder;
                    if (this.selectedEditOrder.id) {
                        this.prioritySelected = this.selectedEditOrder.priority ?? this.priorities[0];
                        this.customerSelected = this.selectedEditOrder.customers ?? this.customers[0];
                        this.routeSelected = this.selectedEditOrder.productionRoute ?? undefined;

                        this.selectedProductionProcesses = [];
                        this.selectedEditOrder.productionOrderProcesses?.forEach(orderProcess => {
                            if (!this.selectedProductionProcesses.some((process) => process.id === orderProcess.productionProcess.id)) {
                                this.selectedProductionProcesses = [...this.selectedProductionProcesses,orderProcess.productionProcess];
                            }
                        });

                        this.productionProcessTableRows = this.selectedEditOrder.productionOrderProcesses ?? [];
                        this.selectedUsers = this.selectedEditOrder.operators ?? [];

                    }
                },
                error: () => {
                    this.toast.error('Error cargando ordenes de producción');
                }
            });

        await this.articleService.setupWebSocket();
        this.articleService.articles$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (articles) => {
                    this.articles = articles;
                    this.originalArticleStocks = _.cloneDeep(this.articles);
                    this.articleSelected = this.articles[this.articles.length - 1];
                },
                error: () => {
                    this.toast.error('Error cargando artículos');
                }
            });

        await this.machineService.setupWebSocket();
        this.machineService.machines$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (machines) => {
                    this.machines = machines;
                },
                error: () => {
                    this.toast.error('Error cargando máquinas');
                }
            });

        await this.assessService.setupWebSocket();
        this.assessService.assessments$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (assessments) => {
                    this.assessments = assessments;
                    this.assessSelected = this.assessments[this.assessments.length - 1];
                },
                error: () => {
                    this.toast.error('Error cargando evaluaciones');
                }
            });

        await this.customerService.setupWebSocket();
        this.customerService.customers$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (customers) => {
                    this.customers = customers;
                    this.customerSelected = this.customers[this.customers.length - 1];
                },
                error: () => {
                    this.toast.error('Error cargando clientes');
                }
            });

        await this.productionRouteService.setupWebSocket();
        this.productionRouteService.productionRoutes$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (routes) => {
                    this.routes = routes;
                    const previousIndexRouteSelected = this.routes.findIndex(route => route.id === this.routeSelected?.id);
                    this.routeSelected = this.routes[previousIndexRouteSelected ?? null];
                    if (this.routeSelected) {
                        const newProductionProcessSelectedIds = this.routeSelected.productionProcesses?.map(process => process.productionProcessId);
                        const newProductionProcessessSelected = newProductionProcessSelectedIds!
                            .map(id => this.productionProcessess.find(process => process.id === id))
                            .filter((process): process is ProductionProcessDto => process !== undefined);

                        if (newProductionProcessessSelected.length > 0) {
                            this.onProductionProcessessChange(newProductionProcessessSelected, true);
                        }
                        this.onRouteChange(this.routeSelected);
                    }
                },
                error: () => {
                    this.toast.error('Error cargando rutas de producción');
                }
            });

        await this.productionProcessesService.setupWebSocket();
        this.productionProcessesService.productionProcessess$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (productionProcessess) => {
                    this.productionProcessess = productionProcessess;
                },
                error: () => {
                    this.toast.error('Error cargando procesos de producción');
                }
            });

        await this.articleBatchesService.setupWebSocket();
        this.articleBatchesService.articleBatches$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (articleBatches) => {
                    this.articlesBatches = articleBatches;
                    this.originalArticleBatchesStocks = _.cloneDeep(this.articlesBatches);
                },
                error: () => {
                    this.toast.error('Error cargando lotes de artículos');
                }
            });

        await this.usersService.setupWebSocket();
        this.usersService.users$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (users) => {
                    this.users = users;
                },
                error: () => {
                    this.toast.error('Error cargando ordenes de producción');
                }
            });

        this.priorities = await this.productionOrderService.getPriorities();
        this.prioritySelected = this.priorities[0];

        this.resetProductionProcessesData();
    }

    ngOnDestroy(): void {
        this.productionOrderService.disconnectWebSocket();
        this.articleService.disconnectWebSocket();
        this.machineService.disconnectWebSocket();
        this.assessService.disconnectWebSocket();
        this.customerService.disconnectWebSocket();
        this.productionRouteService.disconnectWebSocket();
        this.articleBatchesService.disconnectWebSocket();
        this.productionProcessesService.disconnectWebSocket();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    async ngAfterViewInit(): Promise<void> {
        setTimeout(() => {
            this.tableHeight =
                (this._tableContainer.nativeElement as HTMLImageElement)
                    .clientHeight - 45; // X depend of your page display
        });
    }

    setShowFinished(value: boolean): void {
        this.showFinished = value;
    }

    async getByScreen(): Promise<ProductionOrderDto[]> {
        this.loading = true;

        try {
            const productionOrder = await this.productionOrderService.getByScreen(this.screenLogged!.id);
            return productionOrder;
        } catch (error: any) {
            this.loading = false;

            this.toast.error(`No se han podido obtener las ordenes por pantalla. Error: ${error.message}`);

            return [];
        } finally {
            this.loading = false;
        }
    }

    async filterOrders(): Promise<void> {
        const filtered = this.productionOrders.filter((order) => {
            const idMatch = order.id
                .toString()
                .includes(this.filterText.toLowerCase());

            const customerMatch = (order.customers as CustomerDto)
                .name!.toLowerCase()
                .includes(this.filterText.toLowerCase());

            const machineMatch = (order.productionOrderProcesses as ProductionOrderProcessDto[]).some(
                (process) => {
                    if (process.machine != undefined) {
                        return process.machine.name
                            .toLowerCase()
                            .includes(this.filterText.toLowerCase());
                    } else {
                        return false;
                    }
                },
            );

            return idMatch || customerMatch || machineMatch;
        });

        this.productionOrdersFiltered = _.cloneDeep(filtered);
    }

    async onQueryParamsChange(params: NzTableQueryParams): Promise<void> {
        const { pageSize, pageIndex, sort } = params;

        this.sort = sort;
        this.tamPage = pageSize;
        this.numPage = pageIndex;
        this.loading = true;

        // TODO: utilizar hasPermission en vez de  por el rol
        if (this.isAdmin && this.screenLogged !== null) {
            this.productionOrders = await this.getByScreen();
            this.productionOrdersFiltered = _.cloneDeep(this.productionOrders);
        } else {
            // ToDo: Cuando se habilite por configuración el filtrado de órdenes por operador, entonces habrá que habilitar esto
            // this.productionOrders = await this.productionOrderService.findAllByUserId(this.userLoggedService.userLogged.id);
            this.productionOrders = await this.productionOrderService.findAll();
            this.productionOrdersFiltered = _.cloneDeep(this.productionOrders);
        }

        this.loading = false;
    }

    calculateStepper(order: any): number {
        const orderProcesses = order.productionOrderProcesses || [];

        const totalDone = orderProcesses.filter(
            (elem: any) => elem.status === ProductionOrderStatesEnum.FINISHED,
        ).length;

        return totalDone === 0 ? 0 : Math.round((totalDone / orderProcesses.length) * 100);
    }

    setOrderSelected(event: any, order: any): void {
        if (this.orderSelected != null && order !== null) {
            const orderFound = this.productionOrdersFiltered.find(
                (orderA: any) => orderA.id === (this.orderSelected as ProductionOrderDto).id,
            );

            if (event && event.target.nodeName !== 'BUTTON') {
                this.orderSelected = _.cloneDeep(order);
                order.expand = false;
            } else if (
                this.orderSelected !== null &&
                this.orderSelected !== undefined &&
                this.orderSelected.id !== order.id
            ) {
                if (orderFound !== undefined) {
                    // orderFound.expand = false;
                }

                this.orderSelected = _.cloneDeep(order);
                order.expand = true;
            } else if (
                this.orderSelected !== null &&
                this.orderSelected !== undefined &&
                this.orderSelected.id === order.id
            ) {
                if (orderFound !== undefined) {
                    // orderFound.expand = false;
                }

                this.orderSelected = _.cloneDeep(null);
                order.expand = false;
            } else {
                this.orderSelected = _.cloneDeep(order);
                order.expand = true;
            }
        } else {
            this.orderSelected = _.cloneDeep(order);
        }
    }

    async customerFilter(customerName: string): Promise<void> {
        this.title = ' - ' + customerName;
        this.customerName = customerName;
        this.loading = true;

        if (this.isAdmin && this.screenLogged !== null) {
            await this.getByScreen();
        } else {
            this.customerName = customerName;
            this.filterText = customerName;
            await this.filterOrders();
        }

        this.loading = false;
    }

    navigateToOrden(orderId: number): void {
        this.router.navigate([`/production-orders/${orderId}`]);
    }

    datesAreOnSameDay(date: Date): boolean {
        return datesAreOnSameDay(new Date(), new Date(date));
    }

    getTotalDuration(
        startDate: Date,
        endDate: Date,
        pauses: ProductionPauseDto[],
    ): string {
        //TODO: Revisar si concuerdan los tiempos
        let pausesMilliseconds: number = 0;

        for (let i = 0; i < pauses.length; i++) {
            const pausa = pauses[i];
            pausesMilliseconds +=
                new Date(pausa.startDate).getTime() -
                new Date(pausa.endDate).getTime();
        }

        const millisec: number =
            new Date(endDate).getTime() - new Date(startDate).getTime();
        const millisecToUse: number = millisec - pausesMilliseconds;

        let seconds: number = +(millisecToUse / 1000).toFixed(0);
        let minutes: number = Math.floor(seconds / 60);
        let hours: number = 0;

        if (minutes > 59) {
            hours = Math.floor(minutes / 60);
            minutes = minutes - hours * 60;
        }

        seconds = Math.floor(seconds % 60);

        if (hours > 0) {
            return `${hours >= 10 ? '' : '0'}${hours} : ${minutes >= 10 ? '' : '0'}${minutes} : ${seconds >= 10 ? '' : '0'}${seconds}`;
        }

        return `${minutes >= 10 ? '' : '0'}${minutes} : ${seconds >= 10 ? '' : '0'}${seconds}`;
    }

    saveLastTextFilter() {
        sessionStorage.setItem('lastFilter', this.filterText);
    }

    async clearFilter(): Promise<void> {
        this.loading = true;
        this.filterText = '';
        this.customerName = '';
        this.numPage = 1;
        sessionStorage.setItem('lastFilter', '');

        if (this.isAdmin && this.screenLogged !== null) {
            this.productionOrders = await this.getByScreen();
        } else {
            this.productionOrders = await this.productionOrderService.findAll();
        }

        this.productionOrdersFiltered = _.cloneDeep(this.productionOrders);

        this.loading = false;
    }

    async openCreateOrderDrawer(): Promise<void> {
        try {
            this.fieldsReadOnly = false;
            this.selectedEditOrder = new ProductionOrderDto();
            this.drawerTitle = 'Creando nueva orden de producción';
            this.prioritySelected = this.priorities[0];
            this.isNewOrder = true;
            this.productionProcessTableRows = [];
            this.orderStarted = false;
            this.openDrawer();
        } catch (e: any) {
            this.toast.create('error', `${e.message}`);

            this.closeDrawer();
        }
    }

    viewOrder(id: number): void {
        this.fieldsReadOnly = true;

        this.selectedEditOrder = this.productionOrdersFiltered.find(
            (order) => order.id === id,
        ) as ProductionOrderDto;

        this.drawerTitle = 'Viendo orden de producción ' + this.selectedEditOrder?.id;

        this.startDate = this.selectedEditOrder.startDate
            ? new Date(this.selectedEditOrder.startDate)
            : new Date();

        this.deadLineDate = this.selectedEditOrder.deadlineDate
            ? new Date(this.selectedEditOrder.deadlineDate)
            : new Date();

        this.confirmatedDate = this.selectedEditOrder.confirmationDate
            ? new Date(this.selectedEditOrder.confirmationDate)
            : new Date();

        this.deliveryDate = this.selectedEditOrder.deliveryDate
            ? new Date(this.selectedEditOrder.deliveryDate)
            : new Date();
        this.orderStarted = false;
        this.setData();
        this.openDrawer();
    }

    editOrder(id: number): void {
        this.selectedEditOrder = this.productionOrdersFiltered.find(
            (order) => order.id === id,
        ) as ProductionOrderDto;
        this.orderStarted = this.selectedEditOrder.productionOrderProcesses?.some(
            (p) => !p.status?.isStartingStatus,
        ) as boolean;

        this.fieldsReadOnly = false;
        this.drawerTitle = 'Editando orden de producción ' + this.selectedEditOrder?.id;
        this.isNewOrder = false;
        this.setData();
        this.openDrawer();
    }

    compareUsers = (o1: any, o2: any): boolean => {
        return o1 && o2 ? o1.id === o2.id : o1 === o2;
    };

    setData(): void {
        try {
            this.customerSelected = this.customers.find(
                (c) => c.id === (this.selectedEditOrder.customers as CustomerDto).id,
            ) as CustomerDto;

            if (this.selectedEditOrder.productionRoute) {
                this.routeSelected = this.routes.find(
                    (r) => r.id === (this.selectedEditOrder.productionRoute as ProductionRouteDto).id,
                );
            }

            this.prioritySelected = this.priorities.find(
                (p) => p.id === this.selectedEditOrder.priorityId,
            ) as PriorityDto;

            this.selectedUsers = [...this.selectedEditOrder?.operators!];

            this.productionProcessTableRows = [];

            (this.selectedEditOrder.productionOrderProcesses as ProductionOrderProcessDto[]).forEach((orderProcess: ProductionOrderProcessDto) => {
                const foundProcess = this.productionProcessess.find((process) => process.id === orderProcess.productionProcess.id);
                if (!foundProcess) {
                    return;
                }
                if (!this.selectedProductionProcesses.some((process) => process.id === foundProcess.id)) {
                    this.selectedProductionProcesses.push(foundProcess);
                }
                this.previousProductionProcessTableRows.push(foundProcess);

                const createdOrderProcess: ProductionOrderProcessDto = {
                    id: orderProcess.id,
                    productionProcess: foundProcess,
                    description: orderProcess.description,
                    quantityToProduce: orderProcess.quantityToProduce,
                    machine: this.machines.find((machine) => machine.id === orderProcess.machine?.id) as MachineDto,
                    article: this.articles.find((article) => article.id === orderProcess.article?.id) as ArticleDto,
                    assess: this.assessments.find((assess) => assess.id === orderProcess.assess?.id) as AssessDto,
                    rawMaterials: orderProcess.rawMaterials,
                    articleBatches: orderProcess.articleBatches ?? [],
                    status: orderProcess.status
                };

                this.productionProcessTableRows.push(createdOrderProcess);
            });
        } catch (e: any) {
            this.toast.create('error', `Error inicializando. Error: ${e.message}`);
        }
    }

    async deleteOrder(orderId: number): Promise<void> {
        try {

            this.selectedEditOrder = this.productionOrdersFiltered.find(
                (order) => order.id === orderId,
            ) as ProductionOrderDto;

            this.orderStarted = this.selectedEditOrder.productionOrderProcesses?.some(
                (p) => !p.status?.isStartingStatus,
            ) as boolean;

            if (this.orderStarted) {
                this.toast.error('No se puede eliminar una orden que ya ha sido iniciada');
                return;
            }

            const deleteConfirmation: boolean = window.confirm(`¿Estás seguro de que quieres eliminar la orden de producción ${orderId}? Esta acción no se puede deshacer.`);

            if (!deleteConfirmation) {
                return;
            }

            const deletedOrder: ProductionOrderDto = await this.ordersService.deleteOrder(orderId);

            if (!deletedOrder) {
                this.toast.create('error', `Ha habido un error eliminando la orden de producción ${orderId}`);
            }

            this.productionOrders = this.productionOrders.filter(o => {
                return o.id != orderId;
            });

            this.filterOrders();

            this.filterOrders();

            this.toast.create('success', 'Orden de producción eliminada correctamente');
        } catch (e: any) {
            this.toast.create('error', `Error eliminando la orden de producción ${orderId}. Error: ${e.message}`);
        }
    }

    async saveEditing(): Promise<void> {
        try {
            const order: ProductionOrderDto = {
                id: this.selectedEditOrder.id,
                description: (<HTMLInputElement>(document.getElementById('description'))).value ? ((<HTMLInputElement>document.getElementById('description')).value as string) : '',
                priorityId: this.prioritySelected.id as number,
                reference: (<HTMLInputElement>(document.getElementById('reference'))).value ? ((<HTMLInputElement>document.getElementById('reference')).value as string) : '',
                customers: this.customerSelected,
                startDate: this.selectedEditOrder.startDate ?? this.startDate,
                deadlineDate: this.deadLineDate,
                confirmationDate: this.confirmatedDate,
                deliveryDate: this.deliveryDate,
                customerId: this.customerSelected.id,
                productionOrderProcesses: this.productionProcessTableRows.map((pp) => {
                    const process = _.cloneDeep(pp);

                    process.articleId = pp.article?.id;
                    process.articleBatches = pp.articleBatches;

                    if (process.articleId != undefined) {
                        delete process.article;
                    }

                    process.rawMaterials = process.rawMaterials.map((rm) => {
                        const rawMaterial = _.cloneDeep(rm);

                        rawMaterial.articleId = rm.article?.id;
                        rawMaterial.articleBatchId = rm.articleBatch?.id;

                        if (rawMaterial.articleId != undefined) {
                            delete rawMaterial.article;
                        }

                        if (rawMaterial.articleBatchId != undefined) {
                            delete rawMaterial.articleBatch;
                        }

                        return rawMaterial;
                    });

                    return process;
                }),
                productionRouteId: this.routeSelected?.id,
                statusId: this.isNewOrder ? ProductionOrderStateIdsEnum.AWAITING : this.selectedEditOrder.statusId,
                operators: this.selectedUsers
            };

            if (!this.isValidForm(order)) {
                return;
            }

            for (let i = 0; i < this.productionProcessTableRows.length; i++) {
                const orderProcess = this.productionProcessTableRows[i];
                if (!(await this.articleService.hasEnoughStock(orderProcess.rawMaterials, orderProcess.quantityToProduce))) {
                    this.toast.info(`No hay suficiente stock para el proceso ${i + 1}, es posible que no se pueda iniciar la producción`);
                }
            }

            if (this.isNewOrder) {
                const createdOrder: ProductionOrderDto = await this.ordersService.createOrder(order);

                this.productionOrders.push(createdOrder);

                this.toast.create('success', 'Orden creada correctamente');
                this.productionOrders = this.ordersService.getOrdersFromLocalStorage();
                this.filterOrders();
            } else if (this.orderStarted) {
                const updatedOrder: ProductionOrderDto = await this.productionOrderService.updateOrderPriority(order.id, this.prioritySelected);

                if (updatedOrder == undefined) {
                    throw new Error('Error updating order priority');
                }

                this.productionOrders = this.ordersService.getOrdersFromLocalStorage();
                this.filterOrders();
                this.toast.create('success', 'Prioridad de la orden actualizada correctamente');
                this.closeDrawer();

            } else {
                const updatedOrder: ProductionOrderDto = await this.ordersService.updateOrder(order);

                const updatedOrderIndex: number = this.productionOrders.findIndex(o => o.id === updatedOrder.id);
                this.productionOrders[updatedOrderIndex] = updatedOrder;

                this.productionOrders = this.ordersService.getOrdersFromLocalStorage();
                this.filterOrders();
                this.toast.create('success', 'Orden actualizada correctamente');
            }

            this.closeDrawer();
        } catch (e: any) {
            this.toast.create('error', `No se ha podido guardar correctamente la orden. ${e.message}`);
        }
    }

    async setSelectedOrderAsUrgent() {
        try {
            if (!this.orderSelected || !this.orderSelected.id) {
                this.toast.create('error', 'No se ha seleccionado ninguna orden de producción');
                return;
            }

            const highestPriorityIndex: number = this.priorities[this.priorities.length - 1].id ?? -1;

            if (highestPriorityIndex < 0) {
                this.toast.create('error', 'No se ha podido encontrar la prioridad más alta');
                return;
            }

            if (this.orderSelected.priorityId === highestPriorityIndex) {
                this.toast.create('error', 'La orden seleccionada ya tiene la prioridad más alta');
                return;
            }

            await this.ordersService.setAsUrgent(this.orderSelected.id);
            this.productionOrders = this.ordersService.getOrdersFromLocalStorage();
            this.filterOrders();

            this.toast.create('success', `Orden ${this.orderSelected.id} marcada como urgente`);
        } catch (e: any) {
            this.toast.create('error', `No se ha podido marcar la orden como urgente. ${e.message}`);
        }
    }

    cancelEditing(): void {
        this.resetQuantityFromLocalStock();
        this.resetProductionProcessesData();
        this.routeSelected = undefined;
        this.closeDrawer();
    }

    openDrawer(): void {
        this.drawerVisible = true;
    }

    closeDrawer(): void {
        this.drawerVisible = false;
        this.resetProductionProcessesData();
    }

    drop(event: CdkDragDrop<string[]>): void {
        if (!this.fieldsReadOnly && !this.orderStarted) {
            moveItemInArray(this.productionProcessTableRows, event.previousIndex, event.currentIndex);
        }
    }

    onRouteChange(route: ProductionRouteDto): void {
        this.productionProcessTableRows = [];
        this.selectedProductionProcesses = [];

        route.productionProcesses?.forEach((process) => {
            const foundProcess = this.productionProcessess.find((p) => p.id === process.productionProcessId);

            if (foundProcess) {
                if (!this.selectedProductionProcesses.includes(foundProcess as ProductionProcessDto)) {
                    this.selectedProductionProcesses = [...this.selectedProductionProcesses, foundProcess as ProductionProcessDto];
                }

                this.productionProcessTableRows.push({
                    productionProcess: foundProcess,
                    description: foundProcess.name,
                    quantityToProduce: 0,
                    rawMaterials: [],
                    articleBatches: [],
                    machine: null,
                    assess: null
                });

                this.previousProductionProcessTableRows = [...this.productionProcessTableRows.map(row => row.productionProcess)];
            }
        });

    }


    onUserChange(newSelectedProcessess: ProductionProcessDto[]): void {
        this.selectedUsers = [...this.orderSelected?.operators!];
    }

    onProductionProcessessChange(newSelectedProcessess: ProductionProcessDto[], updatedFromScoket: boolean = false): void {
        if (newSelectedProcessess.length === 0 && this.previousProductionProcessTableRows.length === 0) {
            return;
        }

        const addedProcesses = newSelectedProcessess.filter(process => {
            const prevProcess = this.previousProductionProcessTableRows.find(prevProcess => prevProcess.id === process.id);
            return !prevProcess || prevProcess.id !== process.id;
        });

        addedProcesses.forEach(addedProcess => {
            this.productionProcessTableRows.push({
                productionProcess: addedProcess,
                description: addedProcess.name,
                quantityToProduce: 0,
                rawMaterials: [],
                articleBatches: [],
                machine: null,
                assess: null
            });

            this.productionProcessTableRows = _.cloneDeep(this.productionProcessTableRows);
        });

        const removedProcesses = this.previousProductionProcessTableRows.filter(prevProcess =>
            !newSelectedProcessess.some(newProcess => newProcess.id === prevProcess.id)
        );

        removedProcesses.forEach(removedProcess => {
            this.productionProcessTableRows = this.productionProcessTableRows.filter(row => row.productionProcess.id !== removedProcess.id);
        });

        if (!updatedFromScoket) {
            this.routeSelected = undefined;
        }

        this.previousProductionProcessTableRows = [...newSelectedProcessess];
    }

    cloneProductionOrderProcess(index: number): void {
        try {
            const newSelectedProductionProcesses: ProductionOrderProcessDto = _.cloneDeep(this.productionProcessTableRows[index]) as ProductionOrderProcessDto;

            if (!newSelectedProductionProcesses) {
                this.toast.create('error', 'No se ha encontrado el proceso de la orden de producción para clonar');
                throw new Error('No se ha encontrado el proceso de la orden de producción para clonar');
            }

            const highestId = Math.max(...this.productionProcessTableRows.map(obj => obj.id ?? 0));
            newSelectedProductionProcesses.id = highestId + 1;

            const result = this.orderProcessService.decreaseStockQuantities(newSelectedProductionProcesses, this.articles, this.articlesBatches);
            this.articles = result.articles;
            this.articlesBatches = result.articlesBatches;

            this.productionProcessTableRows.push(newSelectedProductionProcesses);
            this.routeSelected = undefined;
        } catch (error: any) {
            this.toast.create('error', `Error clonando el proceso de la orden de producción. Error: ${error.message}`);
        }
    }

    removeProductionOrderProcess(idx: number): void {
        try {
            this.orderProcessService.increaseStockQuantities(this.productionProcessTableRows[idx], this.articles, this.articlesBatches);
            const processDeleted = this.productionProcessTableRows.splice(idx, 1);
            this.previousProductionProcessTableRows.splice(idx, 1);

            if (!this.productionProcessTableRows.find(row => row.productionProcess.id === processDeleted[0].productionProcess.id)) {
                this.selectedProductionProcesses = this.selectedProductionProcesses.filter(process => process.id !== processDeleted[0].productionProcess.id);
            }

            this.routeSelected = undefined;
        } catch (error: any) {
            this.toast.create('error', `Error eliminando el proceso de la orden de producción. Error: ${error.message}`);
        }
    }

    compareById(o1: any, o2: any): boolean {
        return o1 && o2 ? o1.id === o2.id : o1 === o2;
    }

    sortByPriority() {
        const sortedProductionOrders = this.productionOrdersFiltered.sort((a, b) => {
            if (this.prioritySortState === 'id') {
                return (a.priority?.level as number) - (b.priority?.level as number);
            } else if (this.prioritySortState === 'asc') {
                return (b.priority?.level as number) - (a.priority?.level as number);
            } else {
                return (a.id as number) - (b.id as number);
            }
        });

        this.productionOrdersFiltered = _.cloneDeep(sortedProductionOrders);

        if (this.prioritySortState === 'asc') {
            this.prioritySortState = 'desc';
        } else if (this.prioritySortState === 'desc') {
            this.prioritySortState = 'id';
        } else {
            this.prioritySortState = 'asc';
        }
    }

    onAddFinalBatches(productionOrderProcess: ProductionOrderProcessDto, processIndex: number) {
        if (!productionOrderProcess.article) {
            this.toast.error(`No hay ningún artículo seleccionado para ${productionOrderProcess.description}`);
            return;
        }

        const modal = this.modalService.create({
            nzTitle: `Lotes de producto final del artículo ${productionOrderProcess.article?.name} - Orden ${productionOrderProcess?.id} ${productionOrderProcess?.description ? '| ' + productionOrderProcess?.description : ''}`,
            nzContent: ModalSelectArticleBatchComponent,
            nzStyle: { width: '90%', top: '20%' },
            nzClosable: false,
            nzFooter: null,
            nzData: {
                data: {
                    orderProcess: productionOrderProcess,
                    canSetProducedQuantity: false,
                    justWatching: this.fieldsReadOnly
                }
            }
        });

        modal.componentInstance?.emitService.subscribe(async (assignedBatches: ArticleBatchDto[]) => {
            this.productionProcessTableRows[processIndex].articleBatches = assignedBatches;
        });
    }

    clearAssess(productionOrderProcess: ProductionOrderProcessDto) {
        productionOrderProcess.assess = null;

        const result = this.orderProcessService.increaseStockQuantities(productionOrderProcess, this.articles, this.articlesBatches);

        productionOrderProcess.rawMaterials = [];

        this.articles = result.articles;
        this.articlesBatches = result.articlesBatches;
    }

    onAssessChange(selectedAssess: any, index: number): void {
        const selectedArticle = this.articles.find(article => article.id === selectedAssess.articleId);

        if (selectedArticle) {
            this.productionProcessTableRows[index].article = selectedArticle;
            this.productionProcessTableRows[index].rawMaterials = [];

            selectedAssess.assessDetails.map((assessDetail: AssessDetailDto) => {
                this.productionProcessTableRows[index].rawMaterials!.push({
                    article: assessDetail.article,
                    quantity: assessDetail.quantity
                });
            });

            this.orderProcessService.decreaseStockQuantities(this.productionProcessTableRows[index], this.articles, this.articlesBatches);
        }
    }

    onShowRawMaterials(productionOrderProcess: ProductionOrderProcessDto) {
        console.log("rawMaterials before: ", productionOrderProcess);

        const modal = this.modalService.create({
            nzTitle: `Materias primas - Orden `,
            nzContent: ModalRawMaterialComponent,
            nzStyle: { width: '90%', top: '15%' },
            nzClosable: false,
            nzFooter: null,
            nzData: {
                data: {
                    orderProcessId: -1,
                    rawMaterials: productionOrderProcess.rawMaterials,
                    quantityToProduce: productionOrderProcess.quantityToProduce,
                }
            }
        });

        modal.componentInstance?.emitService.subscribe((rawMaterials: RawMaterialDto[]) => {
            productionOrderProcess.assess = null;
            productionOrderProcess.rawMaterials = rawMaterials;
            this.orderProcessService.decreaseStockQuantities(productionOrderProcess, this.articles, this.articlesBatches);
        });

    }

    handleDrawerClose(): void {
        this.routeSelected = undefined;
    }

    showButton(btnName: string): boolean {
        const permissionMainKey: string = 'productionOrders';

        return this.permissionService.canRead(permissionMainKey, `${permissionMainKey}.${btnName}`);
    }

    private isValidForm(order: ProductionOrderDto): boolean {
        if (order.description == undefined || order.description === "") {
            this.toast.error('La descripción es requerida');
            return false;
        }

        if (!order.priorityId) {
            this.toast.error('La prioridad es requerida');
            return false;
        }

        if (order.reference == undefined || order.reference === "") {
            this.toast.error('La referencia es requerida');
            return false;
        }

        if (!order.customers) {
            this.toast.error('El cliente es requerido');
            return false;
        }

        // ToDo: Cuando se habilite por configuración el filtrado de órdenes por operador, entonces habrá que habilitar esto
        // if (!order.operators || order.operators.length === 0) {
        //     this.toast.error('Has de seleccionar al menos un operador');
        //     return false;
        // }

        if (this.productionProcessTableRows.length === 0) {
            this.toast.error('Al menos un proceso es requerido');
            return false;
        }

        for (let i = 0; i < this.productionProcessTableRows.length; i++) {
            const productionOrderProcess = this.productionProcessTableRows[i];

            if (!productionOrderProcess.quantityToProduce || productionOrderProcess.quantityToProduce <= 0) {
                this.toast.error(`La cantidad a producir es requerida para el proceso ${i + 1} y debe ser mayor que 0`);
                return false;
            }

            if (!productionOrderProcess.machine) {
                this.toast.error(`La máquina es requerida para el proceso ${i + 1}`);
                return false;
            }

            if (!productionOrderProcess.article && !productionOrderProcess.assess) {
                this.toast.error(`El artículo o escandallo son requeridos para el proceso ${i + 1}`);
                return false;
            }

            if (productionOrderProcess.rawMaterials.length <= 0) {
                this.toast.error(`Las materias primas son requeridas para el proceso ${i + 1}`);
                return false;
            } else {
                for (let j = 0; j < productionOrderProcess.rawMaterials.length; j++) {
                    const rawMaterial = productionOrderProcess.rawMaterials[j];

                    if (!rawMaterial.article) {
                        this.toast.error(`El artículo de materia prima es requerido para el proceso ${i + 1}`);
                        return false;
                    }

                    if (!rawMaterial.quantity || rawMaterial.quantity <= 0) {
                        this.toast.error(`La cantidad de materia prima es requerida para el proceso ${i + 1} y debe ser mayor que 0`);
                        return false;
                    }
                }
            }
        }

        return true;
    }

    private resetProductionProcessesData() {
        this.selectedProductionProcesses = [];
        this.previousProductionProcessTableRows = [];
    }

    private resetQuantityFromLocalStock() {
        this.productionProcessTableRows.forEach(productionOrderProcessCustom => {
            productionOrderProcessCustom.rawMaterials?.forEach(rawMaterial => {
                if (rawMaterial.article) {
                    this.articles.find(article => article.id == rawMaterial.article!.id)!.quantity += rawMaterial.quantity;
                } else if (rawMaterial.articleBatch) {
                    this.articlesBatches.find(articleBatch => articleBatch.id == rawMaterial.articleBatch!.id)!.quantity += rawMaterial.quantity;
                }
            });
        });
    }
}
