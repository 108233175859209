@if (loading) {
<div class="w-screen h-full flex items-center justify-center">
	<svg role="status" class="inline w-6 h-6 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
		viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
			fill="currentColor" />
		<path
			d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
			fill="currentFill" />
	</svg>
</div>
} @else {
<div class="block px-10 pr-14">
	<div class="flex">
		<nz-page-header [nzTitle]="
                    'Orden - ' +
                    idOrder +
                    ' | ' +
                    (orderProcess ? orderProcess.description : '') +
                    ' - '
                " class="p-0 m-0 mt-10">
			<nz-page-header-extra>
				<cd-timer #basicTimer class="font-semibold ml-4 text-[20px]" [autoStart]="false" (onTick)="handleOnTickTimer()"></cd-timer>
			</nz-page-header-extra>
			<nz-page-header-tags>
				<div class="flex items-center w-full justify-between">
					<span [ngClass]="orderProcess.status?.cssClassName">
						{{ orderProcess.status?.name }}
					</span>
				</div>
			</nz-page-header-tags>
			<nz-breadcrumb nz-page-header-breadcrumb>
				<nz-breadcrumb-item>
					<a [routerLink]="'/production-orders'">
						<span>Órdenes</span>
					</a>
				</nz-breadcrumb-item>
				<!--
          <nz-breadcrumb-item *ngIf="this.userLogged!.rol === 'Administrador'">
            <a [routerLink]="['production-orders/', idOrder]">
              <span>Orden - {{ orden? orden.DesArtic: '' }}</span>
            </a>
          </nz-breadcrumb-item>
          -->
				<nz-breadcrumb-item [routerLink]="['/production-orders']" [queryParams]="{
                            customerName: (orderProcess.productionProcess)?.name}">
					{{ orderProcess ? orderProcess.productionProcess?.name : "" }}
				</nz-breadcrumb-item>
			</nz-breadcrumb>

			@if (orderProcess) {
			<nz-page-header-content>
				<div class="flex justify-between">
					<div class="w-2/4 2xl:w-2/3">
						<nz-descriptions nzSize="small" [nzColumn]="2">
							<nz-descriptions-item nzTitle="Núm. Orden" [nzSpan]="1"><b>{{
									this.idOrder
									}}</b></nz-descriptions-item>
							<nz-descriptions-item nzTitle="Pedido" [nzSpan]="1"><b>{{
									order.reference
									}}</b></nz-descriptions-item>
						</nz-descriptions>
						<nz-descriptions nzSize="small" [nzColumn]="2">
							<nz-descriptions-item nzTitle="Descripción" [nzSpan]="2"><b>{{ order.description }}</b>
							</nz-descriptions-item>
						</nz-descriptions>
						<nz-descriptions nzSize="small" [nzColumn]="2">
							<nz-descriptions-item nzTitle="Cliente" [nzSpan]="1"><b>{{
									order.customers?.name
									}}</b></nz-descriptions-item>
							<nz-descriptions-item nzTitle="Fecha" [nzSpan]="1"><b>{{
									(orderProcess.productionTime?.startDate
									| date: "dd/MM/YYYY") || "Sin fecha"
									}}</b>
							</nz-descriptions-item>
						</nz-descriptions>
						<nz-descriptions nzSize="small" [nzColumn]="2">
                            <nz-descriptions-item nzTitle="Proceso" [nzSpan]="1"><b>{{
                                    orderProcess.productionProcess!.name
                                    }}</b>
                            </nz-descriptions-item>
                            <nz-descriptions-item nzTitle="Máquina" [nzSpan]="1"><b>{{
                                    orderProcess.machine
                                    ? orderProcess.machine.name
                                    : "CUALQUIERA DE " +
                                    orderProcess.productionProcess!
                                    .name
                                    }}</b>
                            </nz-descriptions-item>
						</nz-descriptions>
						<nz-descriptions nzSize="small" [nzColumn]="2">
							<nz-descriptions-item nzTitle="Artículo" [nzSpan]="1"><b>{{
									orderProcess.article?.name
									}}</b></nz-descriptions-item>
							<nz-descriptions-item nzTitle="Cantidad" [nzSpan]="1"><b>{{
									orderProcess.article?.quantity
									}}</b>
							</nz-descriptions-item>
						</nz-descriptions>
						@if (orderProcess.article?.useBatches) {
							<nz-descriptions nzSize="small" [nzColumn]="1">
								<nz-descriptions-item nzTitle="Lotes" [nzSpan]="1">
									@for(batch of orderProcess.articleBatches; track batch;) {
                                        <b>{{batch.batchNumber}} &nbsp;</b>
                                    }
								</nz-descriptions-item>
							</nz-descriptions>
						}
						<!-- <nz-descriptions nzSize="small" [nzColumn]="2">
          <nz-descriptions-item nzTitle="Subproceso" [nzSpan]="1"><b>{{process.subproductionProcess ?
          process.subproductionProcess.name : '-' }}</b>
        </nz-descriptions-item> -->
						<!-- TODO: MOSTRAR LOS LABELS DEL NOMBRE DE LOS PROCESOS PRODUCTIVOS SIGUIENTES
        <nz-descriptions-item *ngIf="this.orden !== undefined && this.orden !== null && this.process && utils.countHijos(process.OrdenBifurcacionCabec) !== 0" nzTitle="Siguiente/s proceso/s" [nzSpan]="1"><b class="rounded-md pr-2 pl-2" style="background-color: salmon;">{{lineasSiguientes ? getLabelProcesosSiguientes() : '-'}}</b>
      </nz-descriptions-item>
      -->
						<!-- </nz-descriptions> -->
					</div>
					<div class="w-2/4 flex justify-around items-center flex-wrap">
						<nz-card style="background: #e5e4e2"
							class="text-white font-bold rounded-md min-w-[200px] max-w-[200px] w-[200px] m-6">
							<!-- TODO: EN EL NZSUFIX AÑADIR LAS UNIDADES DE MEDIDA CORRESPONDIENTE-->
							<nz-statistic [nzValue]="orderProcess.quantityToProduce" [nzTitle]="'CANTIDAD PLANIFICADA'"
								[nzSuffix]="orderProcess.article!.measurementUnit!.abbreviation" [nzValueStyle]="{ color: '#CF1322' }"></nz-statistic>
						</nz-card>
						<nz-card style="background: #e5e4e2"
							class="text-white font-bold rounded-md min-w-[200px] max-w-[200px] w-[200px] m-6">
							<!-- TODO: EN EL NZSUFIX AÑADIR LAS UNIDADES DE MEDIDA CORRESPONDIENTE-->
							<nz-statistic [nzValue]="orderProcess.quantityProduced" [nzTitle]="'CANTIDAD PRODUCIDA'"
								[nzSuffix]="orderProcess.article!.measurementUnit!.abbreviation" [nzValueStyle]="{ color: '#CF1322' }"></nz-statistic>
						</nz-card>
                        <nz-card style="background: #e5e4e2"
							class="text-white font-bold rounded-md min-w-[200px] max-w-[200px] w-[200px] m-6">
							<!-- TODO: EN EL NZSUFIX AÑADIR LAS UNIDADES DE MEDIDA CORRESPONDIENTE-->
							<nz-statistic [nzValue]="sensorValue" [nzTitle]="'CANTIDAD TIEMPO REAL'"
								[nzSuffix]="orderProcess.article!.measurementUnit!.abbreviation" [nzValueStyle]="{ color: '#CF1322' }"></nz-statistic>
						</nz-card>
						<!-- TODO: MOSTRAR LOS LABELS DEL NOMBRE DE LOS PROCESOS PRODUCTIVOS SIGUIENTES
    <nz-card *ngIf="cantidadParcialActual != [] && process.UsaCantidadParcialCabec && process.EstCabec === 'P' && getTotalCantidadParcialActual() != 0" style="background: #E5E4E2;" class="text-white font-bold rounded-md min-w-[200px] max-w-[200px] w-[200px] m-6">
      <nz-statistic
        [nzValue]="getTotalCantidadParcialActual()"
        [nzTitle]="'M. PARCIALES ACTUAL'"
        [nzSuffix]="'m'"
        [nzValueStyle]="{ color: '#CF1322' }"
      ></nz-statistic>
    </nz-card>
    <nz-card *ngIf="process.UsaCantidadParcialCabec && utils.countHijos(process.OrdenBifurcacionCabec) > 0 && process.EstCabec === 'P' && totalCantidadParcialSiguiente !== 0" style="background: #E5E4E2;" class="text-white font-bold rounded-md min-w-[200px] max-w-[200px] w-[200px] m-6">
      <nz-statistic class="text-[1px]"
        [nzValue]="totalCantidadParcialSiguiente"
        [nzTitle]="'M. PARCIALES SIGUIENTES'"
        [nzSuffix]="'m'"
        [nzValueStyle]="{ color: '#CF1322' }"
      ></nz-statistic>
    </nz-card>
    -->
					</div>
					<div class="w-2/4 flex justify-between pl-8 2xl:w-1/3 2xl:justify-evenly">
						<button type="button" (click)="startProduction()"
							[disabled]="orderProcess.status?.isEndingStatus || (!orderProcess.status?.isEndingStatus && !orderProcess.status?.isStartingStatus && !orderProcess.status?.isPauseStatus)"
							class="{{
								orderProcess.status?.isEndingStatus || (!orderProcess.status?.isEndingStatus && !orderProcess.status?.isStartingStatus && !orderProcess.status?.isPauseStatus)
                                            ? 'bg-green-900 text-gray-300 opacity-70'
                                            : 'bg-green-400 hover:bg-green-300 text-white'
                                    }} justify-center min-w-[170px] max-w-[170px] w-[170px] min-h-[170px] max-h-[170px] h-[170px] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
							<svg xmlns="http://www.w3.org/2000/svg" class="{{
								orderProcess.status?.name === 'EN MARCHA'
                                                ? 'animate-ping'
                                                : ''
                                        }} w-36 h-36 m-1" fill="none" viewBox="0 0 24 24" stroke="currentColor"
								stroke-width="1">
								<path stroke-linecap="round" stroke-linejoin="round"
									d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
							</svg>
						</button>
						<button type="button" (click)="pauseProcess()"
							[disabled]="orderProcess.status?.isEndingStatus || orderProcess.status?.isPauseStatus || orderProcess.status?.isStartingStatus"
							class="{{
								orderProcess.status?.isEndingStatus || orderProcess.status?.isPauseStatus || orderProcess.status?.isStartingStatus
                                            ? 'bg-yellow-900 text-gray-300 opacity-70'
                                            : 'bg-yellow-300 hover:bg-yellow-300 text-white'
                                    }} justify-center min-w-[170px] max-w-[170px] w-[170px] min-h-[170px] max-h-[170px] h-[170px] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-3xl text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800">
							<svg xmlns="http://www.w3.org/2000/svg" class="{{
								orderProcess.status?.name === 'PAUSADA'
                                                ? 'animate-ping'
                                                : ''
                                        }} w-32 h-32 m-1" fill="none" viewBox="0 0 24 24" stroke="currentColor"
								stroke-width="2">
								<path stroke-linecap="round" stroke-linejoin="round" d="M10 9 v6 m4-6 v6 z" />
							</svg>
						</button>
						<button type="button" (click)="stopButtonAction()" [disabled]="
								orderProcess.status?.isEndingStatus || orderProcess.status?.isStartingStatus
                                    "
							class="{{
								orderProcess.status?.isEndingStatus || orderProcess.status?.isStartingStatus
                                            ? 'bg-red-900 text-gray-300 opacity-70'
                                            : 'bg-red-500 hover:bg-red-300 text-white'
                                    }} justify-center min-w-[170px] max-w-[170px] w-[170px] min-h-[170px] max-h-[170px] h-[170px] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
							<svg xmlns="http://www.w3.org/2000/svg" class="w-36 h-36 m-1" fill="none"
								viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
								<path stroke-linecap="miter" stroke-linejoin="miter"
									d="M9 10a1 1 0 011-1h4a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 01-1-1v-4z" />
							</svg>
						</button>
					</div>
				</div>
			</nz-page-header-content>
			}
		</nz-page-header>
	</div>
	<div class="flex justify-around items-center mt-16 flex-wrap">
		<div class="flex items-center mb-8">
			<!-- <button *ngIf="showButton('btnSetRawMaterial')" (click)="openRawMaterialModal()" -->
			@if (true) {
			<button (click)="openRawMaterialModal()"
				class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-semibold rounded-md text-[13px] px-4 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 min-w-[100px] max-w-[170px] w-[170px] h-[110px] mx-6 uppercase">
				Materias Primas
			</button>
			}
			<!-- <button *ngIf="showButton('btnSetFinalProduct')" (click)="openFinalProductModal()" -->
			@if (orderProcess.article?.useBatches) {
			<button (click)="openBatchModal(false)"
				class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-semibold rounded-md text-[13px] px-4 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 min-w-[100px] max-w-[170px] w-[170px] h-[110px] mx-6 uppercase">
				Producto final
			</button>
			}
			<!-- TODO: COMPROBAR QUE TIENE ESA ACCIÓN EL PROCESO -->
			@if (showButton('btnGenerateTags')) {
			<button
				class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-semibold rounded-md text-[13px] px-4 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 min-w-[100px] max-w-[170px] w-[170px] h-[110px] mx-6 uppercase">
				Generar etiquetas
			</button>
			}
			<!-- TODO: COMPROBAR QUE TIENE ESA ACCIÓN EL PROCESO -->
			@if (showButton('btnEditTags')) {
			<button
				class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-semibold rounded-md text-[13px] px-4 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 min-w-[100px] max-w-[170px] w-[170px] h-[110px] mx-6 uppercase">
				Modificar / Reimpresión de etiquetas
			</button>
			}
		</div>
		<div class="flex items-center mb-8">
			<!-- TODO: COMPROBAR QUE TIENE ESA ACCIÓN EL PROCESO -->
			@if (showButton('btnNewScrap')) {
			<button (click)="openCreateScrapsModal()"
				class="text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:ring-blue-300 font-semibold rounded-md text-[13px] px-4 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 min-w-[100px] max-w-[170px] w-[170px] h-[110px] mx-6 uppercase">
				Nueva Tara
			</button>
			}
			<!-- TODO: COMPROBAR QUE TIENE ESA ACCIÓN EL PROCESO -->
			@if (showButton('btnWatchScraps')) {
			<button (click)="openScrapsModal()"
				class="text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:ring-blue-300 font-semibold rounded-md text-[13px] px-4 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 min-w-[100px] max-w-[170px] w-[170px] h-[110px] mx-6 uppercase">
				Ver Taras
			</button>
			}
		</div>

        <div class="flex items-center mb-8">
			<!-- TODO: COMPROBAR QUE TIENE ESA ACCIÓN EL PROCESO -->
			@if (showButton('btnNewLoss')) {
			<button (click)="openCreateLossesModal()"
				class="text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:ring-blue-300 font-semibold rounded-md text-[13px] px-4 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 min-w-[100px] max-w-[170px] w-[170px] h-[110px] mx-6 uppercase">
				Nueva Merma
			</button>
			}
			<!-- TODO: COMPROBAR QUE TIENE ESA ACCIÓN EL PROCESO -->
			@if (showButton('btnWatchLosss')) {
			<button (click)="openLossesModal()"
				class="text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:ring-blue-300 font-semibold rounded-md text-[13px] px-4 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 min-w-[100px] max-w-[170px] w-[170px] h-[110px] mx-6 uppercase">
				Ver Mermas
			</button>
			}
		</div>
		<!-- TODO: COMPROBAR QUE TIENE ESA ACCIÓN EL PROCESO -->
		<!-- @if (orderProcess.machine.machineMeasurements && orderProcess.machine.machineMeasurements?.length > 0) {
		<div class="flex items-center mb-8">
			<button
				class="text-white bg-orange-400 hover:bg-orange-500 focus:ring-4 focus:ring-blue-300 font-semibold rounded-md text-[13px] px-4 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 min-w-[100px] max-w-[170px] w-[170px] h-[110px] mx-6 uppercase">
				Editar Valor Máquina
			</button>
		</div>
		} -->
		<!-- TODO: COMPROBAR QUE TIENE ESE PERMISO EL USUARIO -->
		<div class="flex items-center mb-8">
			@if (showButton('btnWatchPauses')) {
			<button (click)="openPausesModal()"
				class="text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-blue-300 font-semibold rounded-md text-[13px] px-4 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800min-w-[100px] max-w-[170px] w-[170px] h-[110px] mx-6 uppercase">
				Ver Pausas
			</button>
			}
		</div>
		<!-- TODO: COMPROBAR QUE TIENE ESA ACCIÓN EL PROCESO -->
		<div class="flex items-center mb-8">
			@if (showButton('btnWatchImages')) {
			<button
				class="text-white bg-lime-500 hover:bg-lime-600 focus:ring-4 focus:ring-blue-300 font-semibold rounded-md text-[13px] px-4 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800min-w-[100px] max-w-[170px] w-[170px] h-[110px] mx-6 uppercase">
				Ver Imágenes
			</button>
			}

			@if (showButton('btnWatchPdf')) {
			<button
				class="text-white bg-lime-500 hover:bg-lime-600 focus:ring-4 focus:ring-blue-300 font-semibold rounded-md text-[13px] px-4 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800min-w-[100px] max-w-[170px] w-[170px] h-[110px] mx-6 uppercase">
				VER PDFs
			</button>
			}
		</div>

		<div class="flex items-center mb-8">
			<!-- TODO: COMPROBAR QUE TIENE ESA ACCIÓN EL PROCESO -->
			@if (showButton('btnNewComment')) {
			<button
				class="text-white bg-purple-600 hover:bg-purple-700 focus:ring-4 focus:ring-blue-300 font-semibold rounded-md text-[13px] px-4 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 min-w-[100px] max-w-[170px] w-[170px] h-[110px] mx-6 uppercase">
				Nuevo Comentario
			</button>
			}
			<!-- TODO: COMPROBAR QUE TIENE ESE PERMISO EL USUARIO -->
			@if (showButton('btnWatchComments')) {
			<button
				class="text-white bg-purple-600 hover:bg-purple-700 focus:ring-4 focus:ring-blue-300 font-semibold rounded-md text-[13px] px-4 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 min-w-[100px] max-w-[170px] w-[170px] h-[110px] mx-6 uppercase">
				Ver Comentarios
			</button>
			}
		</div>
	</div>

	<!-- TODO: COMPROBAR QUE TIENE ANOTACIONES -->
	<!-- <div class="mt-2 mb-8">
		<p class="font-medium mb-4 text-[16px]">Anotaciones</p>
		<p class="font-medium" style="white-space: pre-wrap; color: red">
			<b>VARIABLE PARA ANOTACIONES</b>
		</p>
	</div> -->

	<!-- TODO: V2 CANTIDADES PARCIALES
<div class="mt-2 mb-8" *ngIf="!loading && process.UsaCantidadParcialCabec && process.EstCabec !== 'I' && cantidadParcialActual.length !== 0">
  <p class="font-medium mb-4 text-[16px]">Metros parciales</p>
  <nz-steps [nzCurrent]="-1" [nzProgressDot]="progressTemplate">
            <nz-step *ngFor="let metrosParciales of cantidadParcialActual; let j = index" [nzTitle]="metrosParciales.Cantidad+'m'" [nzDescription]="process.EstCabec === 'X' ? 'Actualizado por: '+ metrosParciales.agenteUpdate.NomAgent +' Fecha: '+ (metrosParciales.UpdatedAt| date:
            datesAreOnSameDay(metrosParciales.UpdatedAt) ? 'HH:mm:ss' : 'dd/MM/YYYY HH:mm:ss a (O)') : 'Creado por: '+ metrosParciales.agenteCreador.NomAgent +' Fecha: '+ (metrosParciales.CreatedAt| date:
            datesAreOnSameDay(metrosParciales.CreatedAt) ? 'HH:mm:ss' : 'dd/MM/YYYY HH:mm:ss a (O)')" [nzStatus] = "metrosParciales.Checked ? 'finish':'error'"></nz-step>
  </nz-steps>
  <ng-template #progressTemplate let-dot let-status="status" let-index="index">
    <span nz-popconfirm [nzPopconfirmTitle]="'Metros parciales ' +cantidadParcialActual[index].Cantidad + ' m. - Estado: ' + (cantidadParcialActual[index].Checked ? 'REVISADO' : 'NO REVISADO')"
      [nzOkText]="this.userLogged!.rol === 'Administrador' ? 'Editar' : (cantidadParcialActual[index].Checked ? 'Marcar como no revisado' : 'Marcar como revisado')"
      (nzOnConfirm)="this.userLogged!.rol === 'Administrador' ? openModalEditarCantidadParcial(cantidadParcialActual[index]) : updateCheck(index)" >
      <ng-template [ngTemplateOutlet]="dot">
        <button></button>
      </ng-template>
    </span>
  </ng-template>
</div>
-->

	@if (valoresPresentacion.length > 0) {
    <div class="mt-2 mb-8">
		<p class="font-medium mb-4 text-[16px]">Valores de Presentación</p>		
		<nz-table #valorPresentacionTabla [nzData]="valoresPresentacion" nzTableLayout="auto" [nzBordered]="true"
			nzSize="small" [nzFrontPagination]="false" [nzShowPagination]="false" [nzLoading]="loading">
			<tbody>
				@for (data of valorPresentacionTabla.data; track data; let i = $index) {
				<tr>
					<td class="text-left align-middle font-medium">
						{{ data.CodMP }} /
						{{ data.DescArtic ? data.DescArtic : "-" }}
					</td>
					@for (
					valor of data.valores; track
					valor; let j = $index) {
					<td class="hover:cursor-pointer">
						{{ valor.Cantidad }} ud.
					</td>
					}
				</tr>
				}
			</tbody>
		</nz-table>
	</div>
    }

    @if (machineMeasurements.length > 0) {
    <div class="mt-8">
        <p class="font-medium mb-4 text-[16px]">Valores de Máquina</p>
        <nz-table #machineValuesTable [nzData]="machineMeasurements" nzTableLayout="auto" [nzBordered]="true" nzSize="small"
            [nzFrontPagination]="false" [nzShowPagination]="false" [nzLoading]="loading" class="pb-[100px]">
            <tbody>
                @for (data of machineValuesTable.data; track data; let i = $index) {
                <tr>
                    <td class="text-left align-middle font-medium">
                        {{ data?.measurement?.measurement }}
                    </td>
                    <td class="hover:cursor-pointer">
                        {{ data?.value }}
                    </td>
                </tr>
                }
            </tbody>
        </nz-table>
    </div>
    }

    @if (articleFeatures.length > 0 ?? false) {
    <div class="mt-8">
        <p class="font-medium mb-4 text-[16px]">Características del artículo</p>
        <nz-table #articleFeaturesTable [nzData]="articleFeatures" nzTableLayout="auto" [nzBordered]="true" nzSize="small"
            [nzFrontPagination]="false" [nzShowPagination]="false" [nzLoading]="loading" class="pb-[100px]">
            <tbody>
                @for (data of articleFeaturesTable.data; track data; let i = $index) {
                <tr>
                    <td class="text-left align-middle font-medium">
                        {{ data?.feature?.feature }}
                    </td>
                    <td class="hover:cursor-pointer">
                        {{ data?.value }}
                    </td>
                </tr>
                }
            </tbody>
        </nz-table>
    </div>
    }
</div>
}
