<div class="ml-4 mr-4">
    <nz-table #batchTable [nzData]="articleBatches" [nzLoading]="loading">
        <thead>
            <tr>
                @if(!justWatching) {
                    <th></th>
                }
                <th>Lote</th>
                <th>Cantidad actual</th>
                <th>Cantidad por lote y proceso</th>
                @if (canSetProducedQuantity)
                {
                    <th>Cantidad para añadir</th>
                    <th>Nueva cantidad</th>
                }
                <th>Formatos</th>
            </tr>
        </thead>
        <tbody>
            @for (data of batchTable.data; track data.id; let i = $index) {
            <tr>
                @if(!justWatching) {
                    <td>
                        <label
                        nz-checkbox
                        [disabled]="this.orderProcess.status?.isEndingStatus ?? false"
                        [ngModel]="selectedBatches.indexOf(data.id || -1) > -1"
                        (nzCheckedChange)="setAsSelected(data.id || -1)"
                        ></label>
                    </td>
                }
                <td>{{ data.batchNumber }}</td>
                <td>{{ data.quantity }} {{ data.article?.measurementUnit?.abbreviation }}</td>
                <td>{{ data.quantityPerProcess }} {{ data.article?.measurementUnit?.abbreviation }}</td>
                @if (canSetProducedQuantity)
                {
                    <td>
                        <input type="number" pattern="\d*" class="w-6"
                        [(ngModel)]="articleBatches[i].quantityPerProcess"
                        [disabled]="justWatching"
                        [name]="'quantity'"/>
                    </td>

                    <td>{{ getQuantityValue(data.quantity, articleBatches[i].quantityPerProcess, data.article?.measurementUnit!.abbreviation) }}</td>
                }
                <td>
                    <button
                        type="button" (click)="onAddPackagings(data)" class="p-2">
                        <img src="assets/icons/plus-icon.svg" alt="Añadir formatos" class="h-6 w-6"
                            title="Añadir formatos">
                    </button>
                </td>
            </tr>
            }
        </tbody>
    </nz-table>
    <div *nzModalFooter>
        @if (!justWatching && !this.orderProcess.status?.isEndingStatus ?? true) {
            <p>Si no quieres usar ninguno de estos, puedes generar uno nuevo</p>
            <button
                class="mt-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-[14px] px-5 py-3 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                (click)="generateNewBatch()">
                Generar un nuevo lote
            </button>

            <button
                class="mt-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-[14px] px-5 py-3 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                (click)="save()">
                Guardar
            </button>
        }
        <button
            class="mt-2 mr-6 text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-[14px] px-5 py-3 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            (click)="handleCancel()">
            Cancelar
        </button>
    </div>
</div>