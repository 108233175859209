import { ArticleFeatureValueDto } from './article-feature-value.dto';
import { ArticleTypeDto } from './article-type.dto';
import { ArticlesOnFamiliesDto } from './articles-on-damilies.dto';
import { MachineMeasurementsValueDto } from './machine-measurements-value.dto';
import { MeasurementUnitDto } from './measurement-unit.dto';

export class ArticleDto {
    id!: number;
    externalId?: number;
    name!: string;
    ean!: string;
    alias!: string;
    active!: boolean;
    batchControl!: boolean;
    externalReference!: string;
    useBatches?: boolean;
    quantity!: number;
    measurementUnit!: MeasurementUnitDto;
    measurementUnitId?: number;
    families?: ArticlesOnFamiliesDto[];
    familiesIds?: number[];
    articleType!: ArticleTypeDto;
    articleTypeId?: number;
    machineMeasurementValues?: MachineMeasurementsValueDto[];
    articleFeatures?: ArticleFeatureValueDto[];
}
