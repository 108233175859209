import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { sha256 } from 'js-sha256';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzTableModule } from 'ng-zorro-antd/table';
import { Subject, takeUntil } from 'rxjs';
import { UserLoggedService } from 'src/app/core/services/userLogged.service';
import { ProfileDto } from 'src/app/shared/dto/profile.dto';
import { UserDto } from 'src/app/shared/dto/user.dto';
import { isEmail } from 'src/app/shared/utils/utils';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-users',
    standalone: true,
    imports: [
        NzTableModule,
        NzDividerModule,
        NzPageHeaderModule,
        NzSpaceModule,
        NzDrawerModule,
        NzDatePickerModule,
        FormsModule,
        ReactiveFormsModule,
        NzMessageModule,
        NzDropDownModule,
        NzCheckboxModule,
        NzSelectModule
    ],
    templateUrl: './users.component.html',
    styleUrl: './users.component.scss',
})
export class UsersComponent implements OnInit, OnDestroy {
    readonly userService = inject(UserService);
    readonly message = inject(NzMessageService);
    readonly userLoggedService = inject(UserLoggedService);

    users: UserDto[] = [];
    profileDataSet: ProfileDto[] = [new ProfileDto()];
    drawerVisible = false;
    drawerTitle = 'Placeholder';
    userSelected: UserDto = new UserDto();
    profileSelected: ProfileDto = new ProfileDto();
    externalAccess: boolean = false;
    codeExpirationDate: Date = new Date();
    fieldsReadOnly = false;
    isNewUser = false;
    currentUser: UserDto = new UserDto();
    deleteMe: string = 'deleteMe';
    loadingTable = true;

    private unsubscribe$ = new Subject<void>();

    constructor() { }

    async ngOnInit() {
        this.currentUser = this.userLoggedService.userLogged;

        await this.userService.setupWebSocket();
        this.userService.users$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: async (users) => {
                    this.users = users;
                    if (this.users == undefined || this.users.length === 0) {
                        this.message.error('La lista de usuarios está vacía');
                    }

                    this.profileDataSet = await this.userService.getProfiles();

                    if (this.profileDataSet == undefined || this.profileDataSet.length === 0) {
                        this.message.error('La lista de perfiles está vacía');
                    }

                    this.userSelected = this.users.find(user => user.id === this.userSelected.id) as UserDto;
                    if (this.userSelected) {
                        this.externalAccess = this.userSelected?.externalAccess || false;
                        this.profileSelected = this.userSelected?.profile || new ProfileDto();
                    }
                    this.loadingTable = false;
                },
                error: () => {
                    this.message.error('Error cargando usuarios');
                },
            });
    }

    ngOnDestroy() {
        this.userService.disconnectWebSocket();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    seeUser(uid: number) {
        this.fieldsReadOnly = true;
        this.userSelected = this.users.find((u) => u.id === uid) as UserDto;
        this.drawerTitle = 'Viendo a usuario ' + this.userSelected?.username;
        this.setData();
        this.openDrawer();
    }

    createUser() {
        this.fieldsReadOnly = false;
        this.userSelected = new UserDto();
        this.drawerTitle = 'Creando nuevo usuario';
        this.isNewUser = true;
        this.openDrawer();
    }

    editUser(uid: number) {
        this.fieldsReadOnly = false;
        this.userSelected = this.users.find((u) => u.id === uid) as UserDto;
        this.drawerTitle = 'Editando a usuario ' + this.userSelected?.username;
        this.isNewUser = false;
        this.setData();
        this.openDrawer();
    }

    setData() {
        this.externalAccess = this.userSelected.externalAccess;
        this.codeExpirationDate = this.userSelected.codeExpirationDate
            ? new Date(this.userSelected.codeExpirationDate)
            : new Date();
        this.profileSelected = this.profileDataSet.find(
            (p) => p.id === this.userSelected.profile?.id,
        ) as ProfileDto;
    }

    async deleteUser(uid: number) {
        try {
            if (window.confirm('¿Estás seguro de que quieres eliminar este usuario? Esta acción no se puede deshacer.')) {
                const deletedUser: UserDto = await this.userService.deleteUser(uid);

                if (deletedUser == undefined) {
                    throw new Error('User not found');
                }

                const index = this.users.findIndex((u) => u.id === uid);

                if (index < 0) {
                    this.message.create('error', `No se ha podido eliminar el usuario ${deletedUser.name} con id ${uid}, usuario no encontrado`);
                    throw new Error('User not found');
                }

                this.users.splice(index, 1);
                const data = [...this.users];
                this.users = data;
                this.message.create('success', 'Usuario eliminado correctamente',);
            } else {
                console.log('Cancelando eliminación de usuario ' + uid);
            }
        } catch (e: any) {
            this.message.create('error', `No se ha podido eliminar el usuario. Error: ${e.message}`);
        }
    }

    async saveEditing() {
        try {
            const pass: string = (<HTMLInputElement>document.getElementById('inputPassword'))?.value as string ? sha256((<HTMLInputElement>document.getElementById('inputPassword'))?.value as string) : this.deleteMe;
            const customUserDto: UserDto = {
                id: this.userSelected.id,
                name: (<HTMLInputElement>document.getElementById('name'))?.value
                    ? ((<HTMLInputElement>document.getElementById('name'))
                        ?.value as string)
                    : '',
                firstname: (<HTMLInputElement>document.getElementById('name'))
                    ?.value
                    ? ((<HTMLInputElement>document.getElementById('name'))
                        ?.value as string)
                    : '',
                surname: (<HTMLInputElement>document.getElementById('surname'))
                    ?.value
                    ? ((<HTMLInputElement>document.getElementById('surname'))
                        ?.value as string)
                    : '',
                username: (<HTMLInputElement>document.getElementById('username'))
                    ?.value
                    ? ((<HTMLInputElement>document.getElementById('username'))
                        ?.value as string)
                    : '',
                rfid: (<HTMLInputElement>document.getElementById('rfid'))
                    ?.value
                    ? ((<HTMLInputElement>document.getElementById('rfid'))
                        ?.value as string)
                    : '',
                email: (<HTMLInputElement>document.getElementById('email'))?.value
                    ? ((<HTMLInputElement>document.getElementById('email'))
                        ?.value as string)
                    : '',
                createdBy: this.isNewUser ? this.currentUser.id : this.userSelected.createdBy,
                updatedBy: this.currentUser.id,
                externalAccess: this.externalAccess,
                code: (<HTMLInputElement>document.getElementById('code'))?.value
                    ? ((<HTMLInputElement>document.getElementById('code'))
                        ?.value as unknown as number)
                    : 0,
                codeExpirationDate: this.codeExpirationDate,
                password: pass,
                profileId: this.profileSelected.id,
                profile: this.profileSelected,
            };

            if (customUserDto.password == this.deleteMe) {
                delete customUserDto.password;
            }

            if (!this.validForm(customUserDto)) {
                return;
            }

            if (this.isNewUser) {
                delete customUserDto.profile;

                const createdUser: UserDto = await this.userService.createUser(customUserDto);

                if (createdUser == undefined) {
                    this.message.create('error', 'Usuario no encontrado');

                    return;
                }

                createdUser.profile = this.profileSelected;

                this.users.push(createdUser);
                const data = [...this.users];
                this.users = data;

                this.closeDrawer();

                this.message.create('success', 'Usuario creado correctamente');
            } else {
                const updatedUser: UserDto = await this.userService.updateUser(customUserDto);

                if (updatedUser == undefined) {
                    throw new Error('Error updating user');
                }

                updatedUser.profile = this.profileSelected;

                const index = this.users.findIndex(
                    (u) => u.id === updatedUser.id
                );

                if (index < 0) {
                    this.message.create('error', `No se ha podido actualizar el usuario ${customUserDto.name} con id ' + customUserDto.id + ', usuario no encontrado`);
                    return;
                }

                this.users[index] = updatedUser;

                const data = [...this.users];
                this.users = data;

                this.closeDrawer();
                this.message.create('success', 'Usuario actualizado correctamente');
            }
        } catch (e: any) {
            this.message.create('error', `No se ha podido guardar. Error: ${e.message}`);
        }
    }

    cancelEditing() {
        this.closeDrawer();
    }

    closeDrawer() {
        this.drawerVisible = false;
    }

    openDrawer() {
        this.drawerVisible = true;
    }

    validForm(user: UserDto) {
        console.log(user.name.length);
        if (user.name.length == 0) {
            this.message.create(
                'error',
                'El campo Nombre no puede estar vacío',
            );
            return false;
        }
        if (user.surname.length == 0) {
            this.message.create(
                'error',
                'El campo Apellido no puede estar vacío',
            );
            return false;
        }
        if (user.username.length == 0) {
            this.message.create(
                'error',
                'El campo Username no puede estar vacío',
            );
            return false;
        }
        if (user.password && (user.password.length == 0 && this.isNewUser)) {
            this.message.create(
                'error',
                'El campo Contraseña no puede estar vacío',
            );
            return false;
        }
        if (user.email.length == 0) {
            this.message.create('error', 'El campo Email no puede estar vacío');
            return false;
        }
        if (!isEmail(user.email)) {
            this.message.create(
                'error',
                'El campo Email no es un email válido',
            );
            return false;
        }
        return true;
    }

    compareProfiles = (o1: any, o2: any): boolean => {
        return o1 && o2 ? o1.name === o2.name : o1 === o2;
    };
}
