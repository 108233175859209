import { Component, EventEmitter, inject, Inject, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NzCheckboxComponent } from 'ng-zorro-antd/checkbox';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA, NzModalFooterDirective, NzModalService } from 'ng-zorro-antd/modal';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { ArticleBatchesService } from 'src/app/private/services/article-batches.service';
import { ArticleBatchDto } from 'src/app/shared/dto/article-batch.dto';
import { PackagingDto } from 'src/app/shared/dto/packaging.dto';
import { ProductionOrderProcessDto } from 'src/app/shared/dto/production-order-process.dto';
import { ModalSelectArticlePackagingComponent } from '../modal-select-article-packaging/modal-select-article-packaging.component';

@Component({
    selector: 'app-modal-select-article-batch',
    standalone: true,
    imports: [
        NzTableModule,
        NzModalFooterDirective,
        NzTagModule,
        NzIconModule,
        NzCheckboxComponent,
        FormsModule
    ],
    templateUrl: './modal-select-article-batch.component.html',
    styleUrl: './modal-select-article-batch.component.scss'
})
export class ModalSelectArticleBatchComponent {
    @Output() emitService = new EventEmitter();

    private readonly modalService = inject(NzModalService);
    private readonly toast = inject(NzMessageService);
    private readonly articleBatchService = inject(ArticleBatchesService);

    orderProcess: ProductionOrderProcessDto;
    loading: boolean = true;
    // customArticleBatches: CustomArticleBatch[] = [];
    selectedBatches: number[] = [];
    canSetProducedQuantity: boolean = false;
    justWatching: boolean = false;
    articleBatches: ArticleBatchDto[] = [];

    constructor(@Inject(NZ_MODAL_DATA) public data: any) {
        this.orderProcess = data?.data?.orderProcess;
        this.canSetProducedQuantity = data?.data?.canSetProducedQuantity;
        this.justWatching = data?.data?.justWatching;
    }

    async ngOnInit() {
        if (!this.orderProcess.article) {
            this.loading = false;
            this.toast.error('El proceso de producción no tiene un artículo asociado');

            return;
        }

        this.articleBatches = await this.articleBatchService.getBatchesByArticleId(this.orderProcess.article?.id as number);

        if (this.orderProcess.articleBatches) {
            this.selectedBatches = this.orderProcess.articleBatches.map(batch => batch.id!) ?? [];

            for (const batch of this.orderProcess.articleBatches) {
                const index: number = this.articleBatches.findIndex(b => b.id === batch.id);

                this.articleBatches[index].packagings = batch.packagings;
                this.articleBatches[index].quantityPerProcess = batch.quantityPerProcess;
            }
        }

        this.loading = false;
    }

    async generateNewBatch() {
        try {
            const batch: ArticleBatchDto = {
                quantity: 0,
                articleId: this.orderProcess.article?.id as number,
                quantityPerProcess: 0
            };

            const createdArticleBatch: ArticleBatchDto = await this.articleBatchService.createArticleBatch(batch, this.orderProcess.id!);

            this.articleBatches.push(createdArticleBatch);

            this.toast.success('Lote creado con éxito');
        } catch (e: any) {
            this.toast.error(`Error al crear lote. Error: ${e.message}`);
        }
    }

    setAsSelected(batchId: number) {
        if (this.selectedBatches.includes(batchId)) {
            this.selectedBatches.splice(this.selectedBatches.indexOf(batchId), 1);

            return;
        }

        this.selectedBatches.push(batchId);
    }

    save() {
        if (this.selectedBatches.length === 0) {
            this.toast.error('Has de seleccionar al menos 1 lote');

            return;
        }

        const assignedBatches: ArticleBatchDto[] = this.selectedBatches.map(batchId => {
            const match = this.articleBatches.find(batch => batch.id === batchId)!;

            return match;
        });

        const selectedBatches: ArticleBatchDto[] = this.selectedBatches.map(batchId => {
            const match = this.articleBatches.find(batch => batch.id === batchId)!;

            return match;
        });

        if (this.canSetProducedQuantity && !this.validate(assignedBatches)) {
            return;
        }

        for (const customBatch of selectedBatches) {
            const index: number = assignedBatches.findIndex(b => b.id === customBatch.id);

            assignedBatches[index].quantity += customBatch.quantityPerProcess;
        }

        this.emitService.next(assignedBatches);
        this.modalService.closeAll();
    }

    handleCancel() {
        this.modalService.closeAll();
    }

    onAddPackagings(articleBatch: ArticleBatchDto) {
        if (!articleBatch) {
            this.toast.error(`No se ha facilitado un lote para poder asignar los formatos.`);
            return;
        }

        const selectedArticleBatches: ArticleBatchDto[] = this.selectedBatches.map(selectedBatchId => {
            const match = this.articleBatches.find(batch => batch.id == selectedBatchId)!;

            return match;
        });

        const modal = this.modalService.create({
            nzTitle: `Formatos para el lote ${articleBatch.batchNumber} - Orden ${this.orderProcess?.id} ${this.orderProcess?.description ? '| ' + this.orderProcess?.description : ''}`,
            nzContent: ModalSelectArticlePackagingComponent,
            nzStyle: { width: '90%', top: '20%' },
            nzClosable: false,
            nzFooter: null,
            nzData: {
                data: {
                    currentArticleBatch: articleBatch,
                    selectedArticleBatches: selectedArticleBatches,
                    quantityToProduce: this.orderProcess.quantityToProduce,
                    quantityProduced: this.orderProcess.quantityProduced,
                    canSetProducedQuantity: this.canSetProducedQuantity,
                    justWatching: this.justWatching,
                    articleId: this.orderProcess.article!.id
                }
            }
        });

        modal.componentInstance?.emitService.subscribe(async (assignedPackagings: PackagingDto[]) => {
            if (!assignedPackagings) {
                modal.close();

                return;
            }

            const batchIndex: number = this.articleBatches.findIndex(cab => cab.id === articleBatch.id);

            this.articleBatches[batchIndex].packagings = assignedPackagings;

            modal.close();
        });
    }

    getQuantityValue(quantity: number, quantityPerProcess: number, abbreviation: string): string {
        if (!quantityPerProcess) {
            quantityPerProcess = 0;
        }

        return `${quantityPerProcess + quantity} ${abbreviation}`;
    }

    private validate(assignedBatches: ArticleBatchDto[]): boolean {
        if (!assignedBatches.every(batch => batch.quantity > 0)) {
            this.toast.error('Todos los lotes seleccionados han de tener asignada una cantidad mayor de 0');

            return false;
        }

        const total: number = assignedBatches.reduce((n, { quantityPerProcess }) => n + quantityPerProcess, 0);

        if (this.orderProcess.quantityProduced && this.orderProcess.quantityProduced > 0 && total > this.orderProcess.quantityProduced) {
            this.toast.error(`No puedes añadir a los lotes una cantidad superior de la que se ha producido en el proceso (${this.orderProcess.quantityProduced})`);
            return false;
        }

        return true;
    }
}
