import {
    Component,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { NzButtonComponent } from 'ng-zorro-antd/button';
import { ɵNzTransitionPatchDirective } from 'ng-zorro-antd/core/transition-patch';
import { NzWaveDirective } from 'ng-zorro-antd/core/wave';
import { NzDividerComponent } from 'ng-zorro-antd/divider';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';
import { NzPageHeaderComponent, NzPageHeaderContentDirective, NzPageHeaderExtraDirective } from 'ng-zorro-antd/page-header';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTableCellDirective, NzTableComponent, NzTbodyComponent, NzThMeasureDirective, NzTheadComponent, NzTrDirective } from 'ng-zorro-antd/table';
import { Subject, lastValueFrom, takeUntil } from 'rxjs';
import { AcctionsEdit } from 'src/app/core/enums/acctions-edit';
import { DrawerMode } from 'src/app/core/enums/drawer-mode';
import { UserLoggedService } from 'src/app/core/services/userLogged.service';
import { ScreenService } from 'src/app/public/services/screen.service';
import { ScreenDto } from 'src/app/shared/dto/screen.dto';
import { UserDto } from 'src/app/shared/dto/user.dto';
import { FormService, ItemFormStructure } from 'src/app/shared/services/form.service';
import { SocketService } from 'src/app/shared/services/socket.service';
import { DrawerFormComponent } from '../../components/drawer-form/drawer-form.component';
import { UserService } from '../../services/user.service';
import { AssignMachinesComponent } from '../assign-machines/assign-machines.component';

@Component({
    selector: 'app-screens',
    templateUrl: './screens.component.html',
    styleUrl: './screens.component.scss',
    standalone: true,
    imports: [
        NzPageHeaderComponent,
        NzPageHeaderExtraDirective,
        NzButtonComponent,
        NzWaveDirective,
        ɵNzTransitionPatchDirective,
        NzPageHeaderContentDirective,
        NzTableComponent,
        NzTheadComponent,
        NzTrDirective,
        NzTableCellDirective,
        NzThMeasureDirective,
        NzTbodyComponent,
        NzDividerComponent,
        DrawerFormComponent,
        AssignMachinesComponent,
        NzMessageModule,
        ReactiveFormsModule,
        FormsModule,
        NzSelectModule
    ],
})
export class ScreensComponent implements OnInit, OnDestroy {
    //TODO Falta la implementacion de sockets para la actualizacion y eliminacion de registros.
    @ViewChild('nestedTable', { static: false }) table?: NzTableComponent<ScreenDto>;

    assignMachineSubject: Subject<void> = new Subject<void>();
    drawerMode: DrawerMode = DrawerMode.NONE;
    routeActually: string = '';
    title: string = '';
    formStructure: ItemFormStructure[] = [];
    componentService: any;
    showDrawerForm = false;
    showDrawerAssignMachines = false;

    screens: ScreenDto[] = [];
    users: UserDto[] = [];
    userSelected!: UserDto;
    screenSelected!: ScreenDto;
    loading: boolean = false;
    userName: string | undefined = '';
    userLogged: { name: string; rol: string; token: string } | null = null;
    columns = [
        {
            title: 'Nombre',
            dataIndex: 'nombre'
        },
        {
            title: 'Dirección IP',
            dataIndex: 'ip'
        },
        {
            title: 'Acciones'
        }
    ];

    private readonly unsubscribe$ = new Subject<void>();

    constructor(
        public screensService: ScreenService,
        private userService: UserService,
        private toast: NzMessageService,
        private router: Router,
        private formService: FormService,
        private socketService: SocketService,
        private userLoggedService: UserLoggedService,
    ) { }

    async ngOnInit(): Promise<void> {

        try {
            this.userName = this.userLoggedService.userLogged?.username;
            this.componentService = this.screensService;

            await this.screensService.setupWebSocket();

            this.screensService.screens$
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe({
                    next: (screens) => {
                        this.screens = screens;
                        this.screenSelected = this.screens.find((screen) => screen.id === this.screenSelected?.id) || new ScreenDto();
                    },
                    error: () => {
                        this.toast.error('Error cargando pantallas');
                    }
                });

            await this.userService.setupWebSocket();
            this.userService.users$
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe({
                    next: (users) => {
                        this.users = users;
                    },
                    error: () => {
                        this.toast.error('Error cargando usuarios');
                    }

                });


            this.routeActually = this.router.url;
        } catch (err) {
            this.toast.error('Error cargando pantallas:');
        }
    }

    ngOnDestroy(): void {
        this.screensService.disconnectWebSocket();
        this.userService.disconnectWebSocket();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    async create(): Promise<void> {
        this.drawerMode = DrawerMode.CREATE;
        this.title = 'Crear pantalla';

        const structure = await lastValueFrom(
            this.screensService.getStructure(),
        );

        this.formStructure = _.cloneDeep(
            this.formService.mapEntityToFormStructure(structure),
        );

        this.showDrawerForm = true;
    }

    async edit(data: ScreenDto): Promise<void> {
        this.drawerMode = DrawerMode.EDIT;
        this.title = `Editar pantalla ${data.name}`;
        const screen = this.screens.find((screen) => screen.id === data.id)!;

        const structure = await lastValueFrom(this.screensService.getStructure());

        this.formStructure = _.cloneDeep(
            this.formService.mapEntityToFormStructure(
                structure,
                false,
                screen,
            ),
        );

        this.showDrawerForm = true;
    }

    async view(data: ScreenDto): Promise<void> {
        this.drawerMode = DrawerMode.VIEW;
        this.title = `Pantalla ${data.name}`;
        const screen = this.screens.find((screen) => screen.id === data.id)!;

        const structure = await lastValueFrom(this.screensService.getStructure());

        this.formStructure = _.cloneDeep(
            this.formService.mapEntityToFormStructure(structure, true, screen),
        );

        this.showDrawerForm = true;
    }

    async delete(data: ScreenDto) {
        const res = await lastValueFrom(this.screensService.delete(data.id));

        if (res === 0) {
            this.toast.error('No se ha eliminado ninguna pantalla');
        } else {
            this.toast.error(
                res > 1
                    ? `Se han eliminado ${res} pantallas`
                    : `Se ha eliminado la pantalla ${data.name}`,
            );

            this.socketService.sendNotification(
                AcctionsEdit.DELETE,
                this.routeActually,
                'reload-data',
            );

            this.socketService.sendNotification(
                AcctionsEdit.DELETE,
                this.routeActually + data.id,
                'reload-op',
            );
        }
    }

    openAssignMachinesDrawer(value: any, screenId: any): void {
        if (screenId !== undefined) {
            this.screenSelected = this.screens.find((screen) => screen.id === screenId)!;
            this.showDrawerAssignMachines = value;
        }
    }

    async changeSelected(direction: string): Promise<void> {
        const id = this.formStructure.find(
            (structure) => structure['name'] === 'id',
        )!.value;

        const index = this.screens.findIndex((screen) => screen.id === id);

        let entity = this.screens[index];
        const limit = this.screens.length - 1;

        this.socketService.disconnectSocketRoom(this.routeActually + id);

        if (direction == 'prev' && index > 0) {
            entity = this.screens[index - 1];
        } else if (direction == 'next' && index < limit) {
            entity = this.screens[index + 1];
        }

        if (this.drawerMode === DrawerMode.EDIT) {
            await this.edit(entity);
        } else if (this.drawerMode === DrawerMode.VIEW) {
            await this.view(entity);
        }
    }

    @HostListener('window:beforeunload')
    canDeactivate(): boolean {
        //TODO: not implemented
        return true;
    }
}
