import { Component, HostBinding, inject, OnInit } from '@angular/core';
import { ScreenLoggedService } from 'src/app/core/services/screenLogged.service';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';
import packageJson from '../../../../../package.json';
import { DropdownUserComponent } from './dropdown-user/dropdown-user.component';
import { NzMenuDirective, NzMenuItemComponent } from 'ng-zorro-antd/menu';
import { ɵNzTransitionPatchDirective } from 'ng-zorro-antd/core/transition-patch';
import { NzIconDirective } from 'ng-zorro-antd/icon';
import { NzDropDownADirective, NzDropDownDirective, NzDropdownMenuComponent } from 'ng-zorro-antd/dropdown';
import { Router, RouterLink } from '@angular/router';
import { ScreenDto } from 'src/app/shared/dto/screen.dto';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { PermissionService } from 'src/app/core/services/permission.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    standalone: true,
    imports: [
        RouterLink,
        NzDropDownADirective,
        NzDropDownDirective,
        NzIconDirective,
        ɵNzTransitionPatchDirective,
        NzDropdownMenuComponent,
        NzMenuDirective,
        NzMenuItemComponent,
        DropdownUserComponent,
        NzMenuModule,
    ],
})
export class NavbarComponent implements OnInit {
    // adds padding to the top of the document, so the content is below the header
    @HostBinding('class.bx--header') headerClass = true;

    loggedScreen: ScreenDto | null = null;
    environment: any;
    version: any = packageJson.version;
    currentRoute!: string;

    private readonly permissionService = inject(PermissionService);

    constructor(
        private screenLoggedService: ScreenLoggedService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.loggedScreen = this.screenLoggedService.screenLogged;
        this.environment = _.cloneDeep(environment);

        this.currentRoute = this.router.url;
        this.router.events.subscribe(() => {
            this.currentRoute = this.router.url;
        });
    }

    showNavMenu(navMenuKey: string): boolean {
        const permissionMainKey: string = 'app';

        return this.permissionService.canRead(permissionMainKey, `${permissionMainKey}.${navMenuKey}`);
    }
}
