import { DatePipe, NgClass } from '@angular/common';
import { AfterViewInit, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import _ from 'lodash';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';
import { ProductionOrderDto } from 'src/app/shared/dto/production-order.dto';
import { ORDERS } from 'src/app/core/constants/storage';
import { ProductionPauseDto } from 'src/app/shared/dto/production-pause.dto';
import { NzIconDirective } from 'ng-zorro-antd/icon';
import { NzTableComponent, NzTheadComponent, NzTrDirective, NzTableCellDirective, NzThMeasureDirective, NzTbodyComponent } from 'ng-zorro-antd/table';
import { NzCheckboxComponent } from 'ng-zorro-antd/checkbox';
import { NzDescriptionsComponent, NzDescriptionsItemComponent } from 'ng-zorro-antd/descriptions';
import { ClusterNode, Edge, GraphModule, Layout, Node } from '@swimlane/ngx-graph';
import * as d3 from 'd3';
import { NzBreadCrumbComponent, NzBreadCrumbItemComponent } from 'ng-zorro-antd/breadcrumb';
import { NzButtonComponent } from 'ng-zorro-antd/button';
import { ɵNzTransitionPatchDirective } from 'ng-zorro-antd/core/transition-patch';
import { NzWaveDirective } from 'ng-zorro-antd/core/wave';
import { NzPageHeaderBreadcrumbDirective, NzPageHeaderComponent, NzPageHeaderContentDirective, NzPageHeaderExtraDirective, NzPageHeaderTagDirective } from 'ng-zorro-antd/page-header';
import { Subject } from 'rxjs';
import { ConfigurationDto } from 'src/app/shared/dto/configuration.dto';
import * as utils from '../../../shared/utils/utils';
import { ConfigurationService } from '../../services/configuration.service';
import { ProductionOrderService } from '../../services/production-order.service';
import { ProductionOrderProcessDto } from 'src/app/shared/dto/production-order-process.dto';
import { ConfigurationTypesEnum } from 'src/app/shared/enums/configuration-types.enum';
import { ProductionOrderStatesEnum } from 'src/app/shared/enums/production-order-states.enum';

@Component({
    selector: 'app-production-order-detail',
    templateUrl: './production-order-detail.component.html',
    styleUrl: './production-order-detail.component.scss',
    standalone: true,
    imports: [
        NzPageHeaderComponent,
        NzPageHeaderExtraDirective,
        NzButtonComponent,
        NzWaveDirective,
        ɵNzTransitionPatchDirective,
        NzPageHeaderTagDirective,
        NzBreadCrumbComponent,
        NzPageHeaderBreadcrumbDirective,
        NzBreadCrumbItemComponent,
        RouterLink,
        NzPageHeaderContentDirective,
        NzDescriptionsComponent,
        NzDescriptionsItemComponent,
        NzCheckboxComponent,
        ReactiveFormsModule,
        FormsModule,
        GraphModule,
        NzTableComponent,
        NzTheadComponent,
        NzTrDirective,
        NzTableCellDirective,
        NzThMeasureDirective,
        NzTbodyComponent,
        NgClass,
        NzIconDirective,
        DatePipe,
        NzMessageModule,
    ],
})
export class ProductionOrderDetailComponent
    implements OnInit, AfterViewInit, OnDestroy {
    productionOrderId!: number;
    isLoading: boolean = false;
    order!: ProductionOrderDto;
    nodes: Node[] = [];
    links: Edge[] = [];

    // Graph properties
    clusters: ClusterNode[] = [];
    layout: string | Layout = 'dagreCluster';
    curveType: string = 'Bundle';
    curve: any = d3.curveBundle;
    draggingEnabled: boolean = false;
    panningEnabled: boolean = true;
    zoomEnabled: boolean = false;
    zoomSpeed: number = 0.1;
    minZoomLevel: number = 0.1;
    maxZoomLevel: number = 4.0;
    panOnZoom: boolean = true;
    autoZoom: boolean = true;
    autoCenter: boolean = true;
    center$: Subject<boolean> = new Subject();
    configurations: ConfigurationDto[] = [];


    private readonly activeRoute = inject(ActivatedRoute);
    private readonly configurationService = inject(ConfigurationService);
    private readonly productionOrderService = inject(ProductionOrderService);
    private readonly router = inject(Router);
    private readonly toast = inject(NzMessageService);

    constructor() {
        this.productionOrderId = this.activeRoute.snapshot.params['idOrder'];
        // this.userLogged = _.cloneDeep(this._userLoggedService.userLogged);
    }

    async ngOnInit(): Promise<void> {
        this.isLoading = true;

        const orders = await this.productionOrderService.findAll();

        this.order = orders.find(
                (o) => o.id === +this.productionOrderId,
            ) ??
            this.throwExpression(
                `No existe una orden con el id ${this.productionOrderId}`,
            );

        this.buildForkEdges();

        //this._socketService.conectSocketRoom(this.routeActually, this.userLogged?.user.username);

        this.configurations = await this.configurationService.getConfigurations();

        this.isLoading = false;
    }

    ngAfterViewInit(): void {
        console.log();
        // TODO: SOCKETS POR IMPLEMENTAR
        /*this._socketService.getNotifications('reload-data').subscribe(async (data: any) => {
            this.isLoading = true;
            await this.get();
            this.isLoading = false;
        });*/
    }

    ngOnDestroy(): void {
        console.log();
        // TODO: SOCKETS POR IMPLEMENTAR
        //this._socketService.discconnectSocketRoom(this.routeActually);
    }

    navigateToOperacion(idProcess: number): void {
        try {
            const newTabConfig: ConfigurationDto | undefined = this.configurations.find(
                (c) => c.key === 'productionOrderProcessesDetail.process.openInNewTab'
            );

            if (newTabConfig != null && newTabConfig.type === ConfigurationTypesEnum.BOOLEAN) {
                const newTagConfigValue: boolean = JSON.parse(newTabConfig.value as string);

                if (newTagConfigValue) {
                    window.open(`production-orders/${this.productionOrderId}/process/${idProcess}`);
                    return;
                }
            }

            this.router.navigate([`production-orders/${this.productionOrderId}/process/${idProcess}`]);
        } catch (error: any) {
            throw error;
        }
    }

    buildForkEdges() {
        this.setInterpolationType(this.curveType);

        for (const productionProcess of this.order?.productionOrderProcesses ??
            []) {
            const label: string = `${productionProcess.productionProcess.name} - ${productionProcess.quantityProduced} / ${productionProcess.quantityToProduce}`;

            this.nodes.push({
                id: `${productionProcess.id}`,
                label: label,
                data: {
                    productionOrderId: this.order?.id,
                    productionProcessId: productionProcess.id,
                    quantity: productionProcess.quantityProduced,
                    colors: this.getColorsSVG(productionProcess.status!.name),
                },
                dimension: {
                    height: 30,
                    width: label.length * 9,
                },
            });
        }

        for (const forkEdge of this.order?.forkEdges ?? []) {
            this.links.push({
                id: `link${forkEdge.id.toString()}`,
                source: forkEdge.parentProcessId.toString(),
                target: forkEdge.childProcessId.toString(),
                label: forkEdge.quantity.toString(),
            });
        }
    }

    calculateMillisToDateTime(millisec: number) {
        let seconds: number = +(millisec / 1000).toFixed(0);
        let minutes: number = Math.floor(seconds / 60);
        let hours: number = 0;

        if (minutes > 59) {
            hours = Math.floor(minutes / 60);
            minutes = minutes - hours * 60;
        }

        seconds = Math.floor(seconds % 60);

        if (hours > 0) {
            return `${hours >= 10 ? '' : '0'}${hours} : ${minutes >= 10 ? '' : '0'}${minutes} : ${seconds >= 10 ? '' : '0'}${seconds}`;
        }

        return `${minutes >= 10 ? '' : '0'}${minutes} : ${seconds >= 10 ? '' : '0'}${seconds}`;
    }

    getTotalTime(process: ProductionOrderProcessDto) {
        let milisecPauses: number = 0;

        for (let i = 0; i < process.productionPauses!.length; i++) {
            const pause = process.productionPauses![i];
            milisecPauses += new Date(pause.endDate).getTime() - new Date(pause.startDate).getTime();
        }

        const millisec: number = new Date(process.productionTime!.endDate).getTime() - new Date(process.productionTime!.startDate).getTime();
        const millisecToUse = millisec - milisecPauses;

        return this.calculateMillisToDateTime(millisecToUse);
    }

    datesAreOnSameDay(date: Date): any {
        return utils.datesAreOnSameDay(new Date(), new Date(date));
    }

    // FORKS
    setInterpolationType(curveType: string) {
        this.curveType = curveType;
        if (curveType === 'Bundle') {
            this.curve = d3.curveBundle.beta(1);
        }
        if (curveType === 'Cardinal') {
            this.curve = d3.curveCardinal;
        }
        if (curveType === 'Catmull Rom') {
            this.curve = d3.curveCatmullRom;
        }
        if (curveType === 'Linear') {
            this.curve = d3.curveLinear;
        }
        if (curveType === 'Monotone X') {
            this.curve = d3.curveMonotoneX;
        }
        if (curveType === 'Monotone Y') {
            this.curve = d3.curveMonotoneY;
        }
        if (curveType === 'Natural') {
            this.curve = d3.curveNatural;
        }
        if (curveType === 'Step') {
            this.curve = d3.curveStep;
        }
        if (curveType === 'Step After') {
            this.curve = d3.curveStepAfter;
        }
        if (curveType === 'Step Before') {
            this.curve = d3.curveStepBefore;
        }
    }

    private getColorsSVG(status: string): {
        background: string;
        textColor: string;
    } {
        const attributes = {
            background: '',
            textColor: '',
        };

        switch (status) {
            case ProductionOrderStatesEnum.FINISHED:
                attributes.background = '#F3F4F6';
                attributes.textColor = 'black';
                break;
            case ProductionOrderStatesEnum.AWAITING:
                attributes.background = '#E1EFFE';
                attributes.textColor = '#1E429F';
                break;
            case ProductionOrderStatesEnum.PAUSED:
                attributes.background = '#FDF6B2';
                attributes.textColor = '#723B13';
                break;
            case ProductionOrderStatesEnum.IN_PROGRESS:
                attributes.background = '#DEF7EC';
                attributes.textColor = '#03543F';
                break;
            default:
                attributes.background = '#03543f';
                attributes.textColor = '#DEF7EC';
                break;
        }
        return attributes;
    }

    private throwExpression(errorMessage: string): never {
        throw new Error(errorMessage);
    }
}
