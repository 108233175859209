import { ArticleDto } from './article.dto';
import { MeasurementUnitDto } from './measurement-unit.dto';

export class PackagingDto {
    id?: number;
    externalId?: number;
    externalReference?: string;
    externalBatchNumber?: string;
    name!: string;
    quantityPerPackaging!: number;
    measurementUnitId!: number;
    measurementUnit?: MeasurementUnitDto;
    articleId!: number;
    article?: ArticleDto;
    quantity?: number;
    packagingElementId?: number;
    consumed?: boolean;
    packagingBatchExternalId?: number;
}
